import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL + '/api'
const CLIENT_TEMPORARILY_URL = `${API_URL}/failed-clients`


export const getClientTemporarilyById = (clientId: string | number) => {
  return axios
    .get(`${CLIENT_TEMPORARILY_URL}/${clientId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const updateClientTemporarily = async (data: FormData) => {
  return await axios.post(`${CLIENT_TEMPORARILY_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}