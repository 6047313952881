import {formatDateTime} from '../../../utils/dayJs/format'

type TObservationsHistoryProps = {
  observations: any
}

export const ListObservationsHistory = ({observations}: TObservationsHistoryProps) => {

  return (
    <div className='card-body py-3'>
      <div className='table-responsive'>
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          <thead>
            <tr className='fw-bolder fs-6 text-gray-800'>
              <th>ID</th>
              <th>Criado por</th>
              <th>Observação</th>
              <th>Criado em</th>
            </tr>
          </thead>
          <tbody>
            {observations?.map((item, index) => {
              return (
                <tr key={`observationsHistoryTr${index}`}>
                  <td className='p-5'>{item.id}</td>
                  <td>{item?.user ? item.user.name : ''}</td>
                  <td>{item?.observation ? item.observation : ''}</td>
                  <td>{item?.createdAt ? formatDateTime(item?.createdAt) : ''}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
