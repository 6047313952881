// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import useAuthorization from '../../../../../hooks/useAuthorization'

type Props = {
  row: Row<User>
}

const CustomRow: FC<Props> = ({row}) => {
  const id = String(row.original.clientId) as string
  const isAuthorizedToUpdate = useAuthorization('persons.update')
  const navigate = useNavigate()
  const handleToEdit = () => navigate(`/clientTemporarily/list/${id}/edit`)

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
      <td className='text-center min-w-100px'>
        {isAuthorizedToUpdate && (
          <button className='btn btn-primary me-2' onClick={() => handleToEdit()}>
            Editar
          </button>
        )}
      </td>
    </tr>
  )
}

export {CustomRow}
