import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {ID, toAbsoluteUrl} from '../../_metronic/helpers'
import {getsaleById, getSaleMedicalRecordsById} from './core/_request'
import {Outlet, useParams} from 'react-router-dom'
import {BillingProvider, useBillingContext} from './core/billingProvider'
import {ContractProvider, useContractContext} from './core/ContractProvider'
import {ContractListModal} from './components/contracts-list-modal/ContractListModal'
import {ContractList} from './components/ContractList'
import {BillingList} from './components/BillingList'
import {ItemSaleList} from './components/ItemSaleList'
import {useEffect, useState} from 'react'
import {ISaleResponse} from '../../coreGlobal/models/sale'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {ResponsibleDetail} from './components/ResponsibleDetail'
import {ListSalesMedicalRecords} from './components/ListSalesMedicalRecords'
import {ListSalesHistorySchedules} from './components/ListSalesHistorySchedules'
import Swal from 'sweetalert2'
import {sendLinkChargeToCustomer} from './components/core/_requests'
import {copyToPayment} from '../../utils/copyToPayment'
import {formatDateTime} from '../../utils/dayJs/format'
import {ListSalesStatus} from './components/ListSaleStatus'

const AUTOMATIC_PAYMENTS = [1, 2, 3, 4]

const machineBreadcrumbs: Array<PageLink> = [
  {
    title: 'Vendas',
    path: '/sales',
    isSeparator: false,
    isActive: false,
  },
]

const ListSalesHistoryMedicalRecord = ({data}) => {
  const [dataSale, setDataSale] = useState()

  useEffect(() => {
    const init = async () => {
      const salesHistorySchedules = await getSaleMedicalRecordsById(Number(data))
      setDataSale(salesHistorySchedules)
    }
    init()
  }, [])

  return <ListSalesHistorySchedules salesHistorySchedules={dataSale} />
}

export const SaleShowPage = () => {
  let {id} = useParams()
  const {contractView, setContractView} = useContractContext()
  const [status, setStatus] = useState('')
  const [loadingPage, setLoadingPage] = useState(false)
  const {setBillingData} = useBillingContext()
  const [saleData, setSaleData] = useState<ISaleResponse>()
  const [attempts, setAttempts] = useState(3)

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      const data = await getsaleById(String(id))
      setSaleData(data)
      setBillingData(data.billings)
      setLoadingPage(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (saleData) {
      switch (saleData.status) {
        case 'Pendente':
          setStatus('btn btn-light-warning')
          break
        case 'Pago':
          setStatus('btn btn-light-success')
          break
        case 'Cancelado':
          setStatus('btn btn-light-danger')
          break
        default:
          setStatus('btn btn-light-primary')
      }
    }
  }, [saleData])

  const actionDropdown = async (action: 'RESEND' | 'COPY') => {
    if (action == 'COPY') {
      copyToPayment(saleData?.encryptId!)
      return
    }

    Swal.fire({
      position: 'center',
      icon: 'info',
      title: `Reenviando o Link de pagamento`,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    try {
      await sendLinkChargeToCustomer(saleData?.id)
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Link de pagamnento reenviado com sucesso!',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000,
      })
      setAttempts(attempts - 1)
    } catch (error: any) {
      await Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000,
      })
    } finally {
      Swal.close()
    }
  }

  const hasAutomaticPayment =
    saleData?.billings.some((billing) => AUTOMATIC_PAYMENTS.includes(billing.paymentOption.id)) ||
    false

  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Carregando Detalhamento da Venda'} />
      ) : (
        <>
          <Outlet />
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                          {`${saleData?.client?.id} - ${saleData?.client?.name} - VENDA: ${saleData?.id}`}
                        </a>
                      </div>
                      <div className='fs-4 fw-bolder me-1'>
                        <strong>{formatDateTime(String(saleData?.createdAt))}</strong>
                      </div>
                    </div>
                    <div className='d-flex my-7'>
                      <h1 className='me-2'>
                        <a className={`${status}`}>Status: {saleData?.status}</a>
                      </h1>
                      {saleData?.status === 'Pendente' && hasAutomaticPayment && (
                        <div className='dropdown'>
                          <button
                            className='btn dropdown-toggle bg-redlaser text-white'
                            type='button'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            Ações
                          </button>
                          <ul className='dropdown-menu'>
                            <li>
                              <span
                                className='dropdown-item'
                                onClick={() => actionDropdown('COPY')}
                              >
                                Copiar Link
                              </span>
                            </li>
                            {attempts > 0 && (
                              <li>
                                <span
                                  className='dropdown-item'
                                  onClick={() => actionDropdown('RESEND')}
                                >
                                  Reenviar Link
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className='nav-link active'
                      id='pills-items-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-items'
                      type='button'
                      role='tab'
                      aria-controls='pills-items'
                      aria-selected='true'
                    >
                      Items da Venda
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-cobranca-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-cobranca'
                      type='button'
                      role='tab'
                      aria-controls='pills-cobranca'
                      aria-selected='true'
                    >
                      Historico Cobrança
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-contact-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-tabcontract'
                      type='button'
                      role='tab'
                      aria-controls='pills-tabcontract'
                      aria-selected='true'
                    >
                      Contratos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-medical-records-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-tab-medical-records'
                      type='button'
                      role='tab'
                      aria-controls='pills-tab-medical-records'
                      aria-selected='true'
                    >
                      Prontuário
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-history-schedules-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-history-schedules'
                      type='button'
                      role='tab'
                      aria-controls='pills-history-schedules'
                      aria-selected='true'
                    >
                      Histórico de agendamento
                    </button>
                  </li>
                  {saleData?.responsible && (
                    <li className='nav-item'>
                      <button
                        className='nav-link'
                        id='pills-responsible-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-responsible'
                        type='button'
                        role='tab'
                        aria-controls='pills-responsible'
                        aria-selected='true'
                      >
                        Responsável
                      </button>
                    </li>
                  )}
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-salesHistory-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-salesHistory'
                      type='button'
                      role='tab'
                      aria-controls='pills-salesHistory'
                      aria-selected='true'
                    >
                      Histórico de Status
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <PageTitle breadcrumbs={machineBreadcrumbs}>Detalhamento da Venda</PageTitle>
          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active p-4 pt-0 pt-md-4'
              id='pills-items'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ItemSaleList itemSaleData={saleData?.items} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-cobranca'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <BillingList />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-tabcontract'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ContractList saleId={id} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-tab-medical-records'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesMedicalRecords saleId={Number(id)} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-history-schedules'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesHistoryMedicalRecord data={Number(id)} />
              </div>
            </div>
            {saleData?.responsible && (
              <div
                className='tab-pane fade p-4 pt-0 pt-md-4'
                id='pills-responsible'
                role='tabpanel'
                aria-labelledby='pills-checklist-tab'
              >
                <div className='card mb-5 mb-xl-10'>
                  <ResponsibleDetail responsibleData={saleData?.responsible} />
                </div>
              </div>
            )}
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-salesHistory'
              role='tabpanel'
              aria-labelledby='pills-salesHistory'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesStatus history={saleData?.history} />
              </div>
            </div>
          </div>
          {contractView && <ContractListModal saleId={id} />}
        </>
      )}
    </>
  )
}

export const SalesViewPage = () => {
  return (
    <>
      <ContractProvider>
        <BillingProvider>
          <SaleShowPage />
        </BillingProvider>
      </ContractProvider>
    </>
  )
}
