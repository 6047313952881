/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { KTIcon } from '../../_metronic/helpers'
import { Split } from './components/Split'
import { formatMonetaryToBr, removeMask } from '../../utils/formatMonetaryToBr'
import { PaymentContainer } from './components/PaymentContainer'
import { useCart } from '../../pages/sales/create/core/cartProvider'
import Swal from 'sweetalert2'
import { TokenModalSale } from '../../pages/sales/create/components/Token/modalToken'
import { useNavigate } from 'react-router-dom'
import useAuthorization from '../../hooks/useAuthorization'

const AUTOMATIC_PAYMENTS = [1,2,3,4];

export const SalePaymentConfig = () => {

  const { 
    sum, payment, setShowPaymentModal, sendChargeLinkToCustomer, 
    sale, setSale, generateSaleForPayment, shoppingCart, 
    setOpenTokenModal, openTokenModal, token: { state }
  } = useCart();

  const hasDiscount = state == "REQUEST";
  const hasAutomaticPayment = payment?.some(pay => AUTOMATIC_PAYMENTS.includes(pay.method)) || false;

  useEffect(() => {
    let current = payment?.reduce((previousValue, currentValue) => {
      return Number(previousValue) + Number(removeMask(String(currentValue.value)));
    }, 0);

    if (sum === 0) {
      setEnableGenerate(true);
    } else {
      setEnableGenerate(Number(current).toFixed(2) === Number(sum).toFixed(2));
    }

  }, [payment])

  const [enableGenerate, setEnableGenerate] = useState<boolean>(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const navigateToSale = (sale) => {
    navigate(`/sales/list/${sale}`)
  }

  const isAuthorizedToSkipTokenRequest = useAuthorization("sales.approve_discounts");

  const handleGenerateSaleForPaymentSubmit = () => {

    if (isAuthorizedToSkipTokenRequest || !hasDiscount) {
      generateSaleForPaymentSubmit()
      return;
  }

    setOpenTokenModal(true);
    
  }

  const generateSaleForPaymentSubmit = async () => {
    try {
      sawLoadingSpinner("Gerando cobrança")
      const sale = await generateSaleForPayment()
      setSale(sale)

      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Cobrança gerada com sucesso',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000
      })

      if(!hasAutomaticPayment){
        navigateToSale(sale?.id)
      }

    } catch (error: any) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 2000
      })
    }
  }

  const navigate = useNavigate();

  const sendLinkToCustomer = async () => {
    try {
      sawLoadingSpinner("Enviando link de pagamento")
      await sendChargeLinkToCustomer(sale?.id)
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Link enviado com sucesso',
        showConfirmButton: false,
        timer: 2000
      })
      setShowPaymentModal(false)
      navigateToSale(sale?.id)
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Gerando Cobrança',
        showConfirmButton: false,
        timer: 2000
      })
      setShowPaymentModal(false)
    }
  }

  let current = payment?.reduce((previousValue, currentValue) => {
    return Number(previousValue) + Number(removeMask(String(currentValue.value)));
  }, 0);

  const sawLoadingSpinner = (title = "") => {
    return Swal.fire({
      position: 'center',
      icon: 'info',
      title: title,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  const verifyDisableSendLink = () => {
    if( sale?.id == null ){ return true }
    if ( sum == 0 && shoppingCart.length !== 0 ) { return true }
  }
  
  return (
    <>

      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-800px">
          {
            openTokenModal && <TokenModalSale
              action={() => generateSaleForPaymentSubmit()}
              close={() => setOpenTokenModal(false)}
            />
          }

          <div className="modal-content">
            <div className="modal-header position-relative">
              <div className='text-center w-100'>
                <h3 className="modal-title fw-bold">{sale != null ? "Resumo da venda" : "Escolha a forma de pagamento"}</h3>
              </div>
              <div
                role='button'
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 position-absolute end-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => { setShowPaymentModal(false) }}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className="modal-body">
              <div className="row d-flex m-0">
                <div className={`col-md-${sale == null ? '5' : '12'} col-12 h-100`}>
                  <div className='d-flex justify-content-between mb-1'>
                    <span className="fw-bold d-block text-danger">Total a pagar: </span>
                    <span className="fw-semi-bold d-block text-danger">{formatMonetaryToBr(sum)}</span>
                  </div>
                  <div className='d-flex justify-content-between mb-8'>
                    <span className="fw-bold d-block">Total a selecionar:</span>
                    <span className="fw-semi-bold d-block">{formatMonetaryToBr(sum === 0 ? 0 : sum - current)}</span>
                  </div>
                  <div className="col-12 " style={{ maxHeight: '300px', overflowY: 'auto', overflowX: "hidden" }}>
                    <Split payment={payment} />
                  </div>
                </div>
                {sale == null && <><div className='col-md-1 d-flex' style={{ alignItems: "center", justifyContent: "center" }}>
                  <div className="border border-left-0 border-top-0 border-bottom-0 border-right-2" style={{ width: 1, minHeight: 400 }}></div>
                </div><div className="col-md-6 col-12 h-100 border-left p-0">
                    <h6 className="mb-2">Forma de pagamento</h6>
                    <div className="col-12 " style={{ maxHeight: '400px', overflowY: 'auto', overflowX: "hidden" }}>
                      <PaymentContainer />
                    </div>
                  </div></>}
              </div>
            </div>
            <div className="modal-footer d-flex align-items-center justify-content-center">
              <button
                disabled={!enableGenerate || sale?.id != null}
                onClick={() => {
                  handleGenerateSaleForPaymentSubmit()
                }}
                type="button" className="btn btn-danger">
                {
                  sale?.id != null ? "Cobrança Gerada" : "Gerar Cobrança"
                }
              </button>
              <button
                type="button"
                onClick={() => {
                  sendLinkToCustomer()
                }}
                disabled={verifyDisableSendLink()}
                className="btn btn-white border border-1 border-danger text-danger">
                Enviar link de
                pagamento</button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}
