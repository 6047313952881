import axios, {AxiosResponse} from 'axios'
import {MedicalConditionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const MEDICALCONDITION_URL = `${API_URL}/medical-conditions`

const getMedicalConditions = (query: string): Promise<MedicalConditionsQueryResponse> => {
    return axios
        .get(`${MEDICALCONDITION_URL}?${query}`)
        .then((d: AxiosResponse<MedicalConditionsQueryResponse>) => d.data)
}

export {getMedicalConditions};