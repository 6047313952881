export const getColorStatus = (status) => {
    const statusColors = {
      1: '#ececec',//Agendado
      2: '#ADD8E6',//Confirmado
      3: '#98FB98',//Compareceu
      4: '#FFA07A',//Faltou
      5: '#DDA0DD',//Cancelado Cliente
      6: '#FFDAB9',//Cancelado Clínica
      7: '#98FB98',//Autorizado
      8: '#5c965c',//Finalizado
    };
    return statusColors[status] || '#000000';
  };

// export const getColorClientStatus = (status) => {
//     const statusColors = {
//       1: '#0DCE71',
//       2: '#E1540C',
//       3: '#F7D400'
//     };
//     return statusColors[status] || '#000000';
//   };

export const extractFirstAndLastName = (fullName) => {
    // Divide a string em partes separadas por espaço
    const parts = fullName.split(" ");

    const firstName = parts[0];
    const lastName = parts[parts.length - 1];
    if (firstName === lastName) {
      return `${firstName}`;
    } else {
      return `${firstName} ${lastName}`;
    }

  };

  const verifySocialName = (name, socialName) => {
     if (socialName) {
        return extractFirstAndLastName(socialName)
     } else if (name) {
       return extractFirstAndLastName(name)
     } else {
       return ''
     }
  }

  export function parseToEvent (event) {

    const name = event.client?.name
    const idStatusClient = event.client?.lastClientHistory.status.id
    const socialName = event.client?.physicalPerson?.preferredName

    return ({
        ...event,
        start: new Date(event.dateInit),
        end: new Date(event.dateEnd),
        title: verifySocialName(name, socialName),
        colorStatus: idStatusClient != 2 ? getColorStatus(event.status_id) : event.client?.lastClientHistory.status.color,
        colorClientStatus: event.client?.lastClientHistory.status.color ,
        colorScheduleStatus: getColorStatus(event.status_id),
        resourceId: event.room.id
      });
}
export function parseToEvents (events) {
    return events.map((data) => parseToEvent(data));
}