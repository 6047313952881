import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import axios from 'axios'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { UserModel } from './_models'
import { getUserByToken, logOutUser } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { TFranchiseResponse } from '../../../../coreGlobal/models/franchise/franchise'
import { getUserFranchise } from '../../../../pages/chooseFranchise/core/_request'
import { useNavigate } from "react-router-dom";

type AuthContextProps = {
  currentFranchise: TFranchiseResponse | undefined
  setCurrentFranchise: Dispatch<SetStateAction<TFranchiseResponse | undefined>>
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  currentUser: undefined,
  setCurrentUser: () => { },
  currentFranchise: undefined,
  setCurrentFranchise: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentFranchise, setCurrentFranchise] = useState<TFranchiseResponse | undefined>()

  const logout = async () => {
    try {
      await logOutUser()
      setCurrentUser(undefined)
    } catch (e) { }
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, setCurrentUser, currentFranchise, setCurrentFranchise, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, setCurrentUser, setCurrentFranchise, currentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const navigate = useNavigate();
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const userByToken = await getUserByToken()
          setCurrentUser(userByToken)

          const franchise = await getUserFranchise()
          setCurrentFranchise(franchise)
        }
      } catch (error) {
        if (!didRequest.current && currentUser) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    requestUser()
  }, [])

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          !error.config.url.includes('logout') // Verifica se a URL não contém /logout
        ) {
          logout()
        }

        if (error.response.status === 403 && error.response.data.error === 'Not selected franchise.') {
          navigate('/auth/franquias', { replace: true })
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
