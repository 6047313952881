import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ListWrapper} from './ListWrapper'

const clientsTemporarilyBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Clientes Temporarios',
    path: '/clientsTemporarily',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClientsTemporarilyPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={clientsTemporarilyBreadcrumbs}>
        Lista de Clientes Temporarios
      </PageTitle>
      <ListWrapper />
    </>
  )
}

export default ClientsTemporarilyPage
