import * as Yup from 'yup'
import { MedicalRecordRequest } from '../../../coreGlobal/models/MedicalRecords/medicalRecord'

const frequencySchema = Yup.number()
  .when('status', {
    is: (val) => { return val === 0 },
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().when('category_id', {
      is: (category_id) => { return category_id === 4 },
      then: () => Yup.string().optional(),
      otherwise: () => Yup.string().required()
    })

  })

const observationSchema = Yup.number()
  .when('category_id', {
    is: (val) => { return val === 4 },
    then: () => Yup.string().required('Digite uma observação!'),
    otherwise: () => Yup.string().when('status', {
      is: (status) => { return status === 0 },
      then: () => Yup.string().required(),
      otherwise: () => Yup.string().optional()
    }),
  })

const createMedicalRecordschemas = [
  Yup.object({
    attendant: Yup.number().required().label('Selecione um vendedor!'),
    frequency: frequencySchema,
    observation: observationSchema,
    status: Yup.number().min(0).required().label('Selecione um status!'),
  }),
]

const tokenMedicalRecordschemas = [
  Yup.object({
    token: Yup.number().required().label('Digite um token!'),
  }),
]


const inits: MedicalRecordRequest = {
  itemId: -1,
  attendant: -1,
  status: -1,
  frequency: '',
  observation: ''
}

const scheduleStatusSchema = Yup.object().shape({
  status: Yup.number()
    .moreThan(0, 'Status não permitido')
    .required('Defina um Status'),
  observation: Yup.string().when('status', {
    is: (status) => status === 5 || status === 6,
    then: () => Yup.string().required('Insira a observação para o cancelamento'),
    otherwise: () => Yup.string(),
  }),
});

export { createMedicalRecordschemas, scheduleStatusSchema, tokenMedicalRecordschemas, inits }
