/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const Search = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchPhone, setSearchPhone] = useState<string>('')
  const [searchDocument, setSearchDocument] = useState<string>('')
  const [searchID, setSearchID] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const debouncedSearchPhone = useDebounce(searchPhone, 150)
  const debouncedSearchDocument = useDebounce(searchDocument, 150)
  const debouncedSearchID = useDebounce(searchID, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({name: debouncedSearchTerm, ...initialQueryState})
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (debouncedSearchPhone !== undefined && searchPhone !== undefined) {
      updateState({phone: debouncedSearchPhone, ...initialQueryState})
    }
  }, [debouncedSearchPhone])

  useEffect(() => {
    if (debouncedSearchDocument !== undefined && searchDocument !== undefined) {
      updateState({document: debouncedSearchDocument, ...initialQueryState})
    }
  }, [debouncedSearchDocument])

  useEffect(() => {
    if (debouncedSearchID !== undefined && searchID !== undefined) {
      updateState({clientId: debouncedSearchID, ...initialQueryState})
    }
  }, [debouncedSearchID])

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='name'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Pesquisar Cliente'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className='d-flex align-items-center position-relative ps-14'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='phone'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Pesquisa por telefone'
          value={searchPhone}
          onChange={(e) => setSearchPhone(e.target.value)}
        />
      </div>

      <div className='d-flex align-items-center position-relative ps-14'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='document'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Pesquisa por documento'
          value={searchDocument}
          onChange={(e) => setSearchDocument(e.target.value)}
        />
      </div>

      <div className='d-flex align-items-center position-relative ps-14'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='document'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Pesquisa por ID cliente'
          value={searchID}
          onChange={(e) => setSearchID(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {Search}
