/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import { PaymentPage } from '../../pages/sales/payment'
import AuthCreatePage from "../../pages/chooseFranchise";
import { ResetPassWordPage } from '../../pages/resetPassWord/update'
import { ShowBudgePage } from '../../pages/budgets/show'
import {ChangePasswordPage} from '../../pages/changePassWord/update'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
              <Route path="/auth/franquias" element={<AuthCreatePage />} />
              <Route path="/auth/resetpassword" element={<ResetPassWordPage />} />
            </>
          ) : (
            <>
              <Route path="password-reset" element={<ChangePasswordPage />} />
              <Route path='payment/:id' element={<PaymentPage />} />
              <Route path='budget/:id' element={<ShowBudgePage />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
