

// Objeto de mapeamento para traduções
const permissionMap: { [key: string]: string } = {
    view: "Ver",
    create: "Criar",
    update: "Alterar",
    delete: "Deletar",
    edit: "Editar",
    "approve-discount": "Aprovar desconto",
    "approve_booking": "Aprovar agendamento",
    "fit_in_appointment": "Alterar encaixe",
    "approve_execution": "Aprovar Execuçäo",
    "approve_restrict_client": "Aprovar cliente restrito",
    "approve_discounts": "Aprovar desconto"
};


// Retorna o valor mapeado ou um fallback
export const parsePermissionName = (name: string): string => 
     permissionMap[name?.split(".")[1]?.toLowerCase()] || name.toLowerCase();

const permissionCategoryMap: { [key: string]: string } = {
    franchises: "Franquias",
    sales: "Vendas",
    billings: "Cobranças",
    products: "Produtos",
    machines: "Maquinas",
    users: "Usuarios",
    rooms: "Salas",
    schedules: "Agendamento",
    persons: "Pessoas",
    budgets: "Orçamento",
    categories: "Categorias"
};

export const parsePermissionCategoryName = (name: string): string => 
    permissionCategoryMap[name.toLowerCase()] || name.toLowerCase()