import axios, { AxiosResponse } from 'axios'

import { TFranchiseRequest, TFranchiseResponse, TFranchiseSyncRequest } from '../../../coreGlobal/models/franchise/franchise'
import { ISelect, ISelectProduct } from '../../../coreGlobal/models/default'
import { Response } from '../../../_metronic/helpers'
import { MachineFranchiseRequest, OwnersFranchiseRequest, productFranchiseRequest } from '../../../coreGlobal/models/machines/machine'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const FRANCHISE_URL = `${API_URL}/franchises`
const MACHINE_URL = `${API_URL}/machines`
const PRODUCTS_URL = `${API_URL}/products`

const createFranchise = (data: TFranchiseRequest) => {
  return axios
    .post(FRANCHISE_URL, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateFranchise = (franchiseId: string | number, data: TFranchiseRequest) => {
  return axios
    .put(`${FRANCHISE_URL}/${franchiseId}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateFranchiseSync = (franchiseId: string | number, data: TFranchiseSyncRequest) => {
  return axios
    .put(`${FRANCHISE_URL}/${franchiseId}/config`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getFranchiseById = (id: string): Promise<TFranchiseResponse> => {
  return axios
    .get(`${FRANCHISE_URL}/${id}?include=machines`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateMachines = (machineId: number, data: MachineFranchiseRequest) => {
  return axios
    .post(`${FRANCHISE_URL}/${machineId}/machines`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getMachines = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${MACHINE_URL}/all`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateProducts = (productId: number, data: productFranchiseRequest) => {
  return axios
    .post(`${FRANCHISE_URL}/${productId}/products`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateOwners = (ownerId: number, data: OwnersFranchiseRequest) => {
  return axios
    .post(`${FRANCHISE_URL}/${ownerId}/owners`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getProducts = (): Promise<ISelectProduct[] | undefined> => {
  return axios.get(`${PRODUCTS_URL}/all`)
    .then((response: AxiosResponse<Response<ISelectProduct[]>>) => response.data)
    .then((response: Response<ISelectProduct[]>) => response.data)
}

const syncOldUsersByFranchiseId = (franchiseId: number) => {
  return axios
    .post(`${FRANCHISE_URL}/${franchiseId}/config/sync-old-users`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const syncOldClientsByFranchiseId = (franchiseId: number) => {
  return axios
    .post(`${FRANCHISE_URL}/${franchiseId}/config/sync-old-clients`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const syncOldSalesByFranchiseId = (franchiseId: number) => {
  return axios
    .post(`${FRANCHISE_URL}/${franchiseId}/config/sync-old-sales`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const syncOldSchedulesByFranchiseId = (franchiseId: number) => {
  return axios
    .post(`${FRANCHISE_URL}/${franchiseId}/config/sync-old-events`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export { createFranchise, updateFranchise, getFranchiseById, updateMachines, getMachines, updateProducts, updateOwners, getProducts, syncOldUsersByFranchiseId, syncOldClientsByFranchiseId, updateFranchiseSync, syncOldSalesByFranchiseId, syncOldSchedulesByFranchiseId };