
import { TPaymentOptionRequest, TUpdatePaymentOptionRequest } from "../../../coreGlobal/models/paymentOption";

export const buildCreateRequest = (data: TPaymentOptionRequest)  => {
    return {
        name: data.name,
        category: data.category,
        installmentEnabled: data?.installmentEnabled,
        maxInstallments: data?.maxInstallments,
        installmentFees: data?.installmentFees
    }
}

export const buildUpdateRequest = (data: TUpdatePaymentOptionRequest)  => {
    return {
        name: data.name,
        category: data.category,
        active: data.active,
        installmentEnabled: data?.installmentEnabled,
        maxInstallments: data?.maxInstallments,
        installmentFees: data?.installmentFees

    }
}
