// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {Billing} from '../../../core/_models'
import {useNavigate} from 'react-router-dom'
import {useListView} from '../../core/ListViewProvider'
import useAuthorization from '../../../../../hooks/useAuthorization'

type Props = {
  row: Row<Billing>
}

const CustomRow: FC<Props> = ({row}) => {
  const id = String(row.original.id) as string
  const name = String(row.original.name) as string

  const navigate = useNavigate()

  const isAuthorizedToShow = useAuthorization('billings.view')
  const handleToShow = () => navigate(`/sales/billings/${id}`)

  const {setItemForDelete} = useListView()

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
      <td className='text-center min-w-120px'>
        {isAuthorizedToShow && (
          <button className='btn btn-primary me-2' onClick={() => handleToShow()}>
            <i className='bi bi-search'></i> Visualizar
          </button>
        )}
      </td>
    </tr>
  )
}

export {CustomRow}
