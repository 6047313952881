import { cleanMask } from "../../chooseFranchise/utils/mask"

export const buildUpdateRequest = (data: any): any => { 
    return {
        startTime: data.startTime,
        endTime: data.endTime,
        ...(data.gatewayCashier && {
            gatewayCashier: data.gatewayCashier,
        }),
        ...(data.gatewayHash && {
            gatewayHash: data.gatewayHash,
        }),
        ...(data.cieloMerchantId && {
            cieloMerchantId: data.cieloMerchantId,
        }),
        ...(data.cieloMerchantKey && {
            cieloMerchantKey: data.cieloMerchantKey,
        })
    }
}   