import {useState} from 'react'
import {useClientCart} from '../../core/clientProvider'
import Swal from 'sweetalert2'
import {useCart} from '../../core/cartProvider'
import {SaleButton} from './sale'
import {BudgetButton} from './budget'
import {TokenModalSale} from '../Token/modalToken'
import {LoadingBudget} from '../alert/loadingBudget'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {copyToBudget} from '../../../../../utils/copyToBudget'
import useAuthorization from '../../../../../hooks/useAuthorization'

function getBadge(sum, customerAssociate, responsibleAssociate, shoppingCart) {
  if (customerAssociate == null) {
    return 'Adicione um cliente à venda.'
  } else if (
    customerAssociate.physicalPerson.birthDate &&
    customerAssociate.physicalPerson.age < 18 &&
    responsibleAssociate == null
  ) {
    return 'Adicione um responsável à venda.'
  } else if (sum === 0 && shoppingCart.length === 0) {
    return 'Adicione itens no carrinho :('
  } else if (sum === 0 && shoppingCart.length !== 0) {
    return 'Gerar Cobrança'
  } else {
    return 'Selecionar pagamento.'
  }
}

function getBudget(sum, customerAssociate, budgetData) {
  if (customerAssociate == null) {
    return 'Adicione um cliente à venda.'
  } else if (sum === 0) {
    return 'Adicione itens no carrinho :('
  } else {
    return newOrExistBudget(budgetData)
  }
}

function isNewBudget(budgetData) {
  return budgetData == null
}

function newOrExistBudget(budgetData) {
  return isNewBudget(budgetData) ? 'Gerar Orçamento' : 'Atualizar Orçamento'
}

const verifyDisableButton = (sum, client, responsibleAssociate, shoppingCart) => {
  if (sum == 0 && shoppingCart.length === 0) {
    return true
  }

  if (sum == 0 && shoppingCart.length !== 0) {
    return true
  }

  if (client == null) {
    return true
  }

  if (
    client.physicalPerson.birthDate &&
    client.physicalPerson.age < 18 &&
    responsibleAssociate == null
  ) {
    return true
  }
}

const verifyDisableBudgetButton = (sum, client, shoppingCart) => {
  if (sum == 0 && shoppingCart.length == 0) {
    return true
  }

  if (sum == 0 && shoppingCart.length !== 0) {
    return true
  }
  if (client == null) {
    return true
  }
}

const verifyHasGiftSale = (sum, shoppingCart) => {
  if (sum == 0 && shoppingCart.length !== 0) {
    return true
  }
  return false
}

export const FinishSaleButtons = () => {
  const {
    setShowPaymentModal,
    sum,
    sale,
    setBudgetForSale,
    generateBudget,
    responsibleAssociate,
    shoppingCart,
    setOpenTokenModal,
    openTokenModal,
    budgetData,
    setEnableBudget,
    customerAssociate,
    generateGiftSale,
  } = useCart()
  const {setShowEditPersonModal, client} = useClientCart()
  const navigate = useNavigate()
  const badge = getBadge(sum, client, responsibleAssociate, shoppingCart)
  const budget = getBudget(sum, client, budgetData)
  const [loading, setLoading] = useState(false)
  const [loadingSale, setLoadingSale] = useState(false)
  const isUpdate = budgetData != null
  const {
    token: {state},
  } = useCart()
  const hasDiscount = state == 'REQUEST'
  const hasGiftSale = verifyHasGiftSale(sum, shoppingCart)

  const isAuthorizedToSkipTokenRequest = useAuthorization('sales.approve_discounts')

  const handleBudget = () => {
    if (isAuthorizedToSkipTokenRequest || !hasDiscount) {
      createBudget()
      return
    }

    setOpenTokenModal(true)
  }

  const createGiftSale = async () => {
    setLoadingSale(true)

    try {
      const data = await generateGiftSale()
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: `Venda gerada com sucesso!!!`,
        showConfirmButton: false,
        timer: 2000,
      })

      setLoadingSale(false)
      navigate(`/sales/list/${data?.id}`)
    } catch (error: any) {
      setLoadingSale(false)
      if (error.response.status === 422) {
        let errorText = ''
        for (let key in error.response.data.errors) {
          errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
        }
        await Swal.fire({
          icon: 'error',
          title: 'Ops! Revise seu formulário e tente novamente!',
          html: errorText,
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          timer: 2000,
        })
      }
    }
  }

  const [_, setSearchParams] = useSearchParams()
  const createBudget = async () => {
    setLoading(true)

    try {
      const data = await generateBudget()
      if (!client?.email) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Você não possui email clique no botão abaixo para copiar o link de orçamento',
          confirmButtonText: 'Copiar link de orçamento',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            const encryptId = Array(data).map((data) => data?.encryptId)
            copyToBudget(String(encryptId))
          }
        })
      } else {
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Orçamento ${isUpdate ? 'atualizado' : 'gerado'} com sucesso`,
          showConfirmButton: false,
          timer: 2000,
        })
      }
      setLoading(false)
      setEnableBudget(false)
      setBudgetForSale(data?.id)
      setSearchParams(
        (state) => {
          state.set('budget', String(data?.id))
          return state
        },
        {replace: true}
      )
    } catch (error: any) {
      setLoading(false)
      if (error.response.status === 422) {
        let errorText = ''
        for (let key in error.response.data.errors) {
          errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
        }
        await Swal.fire({
          icon: 'error',
          title: 'Ops! Revise seu formulário e tente novamente!',
          html: errorText,
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          timer: 2000,
        })
      }
    }
  }

  const verifyClient = (client, hasGiftSale) => {
    if (client) {
      const hasEmptyAddress = client.addresses.some((address) => {
        return (
          address.complement === '' ||
          address.number === '' ||
          address.city?.length === 0 ||
          address.neighborhood === '' ||
          address.street === '' ||
          address.zipCode === ''
        )
      })

      const addressesEmpty = client.addresses.length === 0
      const contactEmpty = client.contacts.length === 0
      const emailEmpty = client.email === '' || client.email === null

      const hasEmptyPhysicalPerson =
        client.physicalPerson &&
        (client.physicalPerson.age === null ||
          client.physicalPerson.birthDate === '' ||
          client.physicalPerson.document === '' ||
          client.physicalPerson.gender === '' ||
          client.physicalPerson.nationality?.length === 0 ||
          client.physicalPerson.zipCode === '')

      if (
        hasEmptyAddress ||
        contactEmpty ||
        emailEmpty ||
        hasEmptyPhysicalPerson ||
        addressesEmpty
      ) {
        setShowEditPersonModal(true)
      } else {
        if (hasGiftSale) {
          return createGiftSale()
        }
        return setShowPaymentModal(true)
      }
    }
  }

  const disabledSale = verifyDisableButton(sum, client, responsibleAssociate, shoppingCart)
  const disabledBudget = verifyDisableBudgetButton(sum, client, shoppingCart)

  const isAuthorizedToCreateBudget = useAuthorization('budgets.create')
  const isAuthorizedToUpdateBudget = useAuthorization('budgets.update')

  const isAuthorizedBudget = (budgetData) => {
    const newBudget = isNewBudget(budgetData)

    if (newBudget) {
      return isAuthorizedToCreateBudget
    } else {
      return isAuthorizedToUpdateBudget
    }
  }

  return (
    <>
      {loading && (
        <LoadingBudget title={isUpdate ? 'Atualizando orçamento' : 'Gerando orçamento'} />
      )}

      {loadingSale && <LoadingBudget title={'Gerando Venda'} />}
      <div className={sale ? 'col-12 align-items-end justify-content-end' : 'col-6 px-1'}>
        {!disabledSale && <SaleButton badge={badge} action={() => verifyClient(client, false)} />}
        {hasGiftSale && <SaleButton badge={badge} action={() => verifyClient(client, true)} />}
      </div>
      {sale == null && isAuthorizedBudget(budgetData) && (
        <div className='col-6 px-1'>
          {!disabledBudget && <BudgetButton budget={budget} action={() => handleBudget()} />}
        </div>
      )}

      {openTokenModal && (
        <TokenModalSale
          action={() => {
            createBudget()
          }}
          close={() => setOpenTokenModal(false)}
        />
      )}
    </>
  )
}
