import axios, {AxiosResponse} from 'axios'
import {BillingsQueryResponse} from './_models'
import { ID } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const BILLING_URL = `${API_URL}/billings`

const getBillingById = (id: string | number) => {
    return axios
        .get(`${BILLING_URL}/${id}?include=sale.client.physicalPerson&pixPayment`)
        .then((response: any) => response.data)
        .then((response: any) => response.data)
}

const getBillings = (query: string): Promise<BillingsQueryResponse> => {
    return axios
        .get(`${BILLING_URL}?${query}&include=sale.client.physicalPerson`)
        .then((d: AxiosResponse<BillingsQueryResponse>) => d.data)
}

const deleteBilling = (billingId: ID) => {
    return axios
        .delete(`${BILLING_URL}/${billingId}`)
        .then((d) => d.data)
}

export { getBillingById, getBillings, deleteBilling };