import {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {ItemSaleList} from '../../sales/components/ItemSaleList'
import {BillingList} from '../../sales/components/BillingList'
import {ContractList} from '../../sales/components/ContractList'
import {ResponsibleDetail} from '../../sales/components/ResponsibleDetail'
import {Outlet} from 'react-router-dom'
import {ContractListModal} from '../../sales/components/contracts-list-modal/ContractListModal'
import {useContractContext} from '../../sales/core/ContractProvider'
import {useBillingContext} from '../../sales/core/billingProvider'
import {ListSalesMedicalRecords} from '../../sales/components/ListSalesMedicalRecords'
import {ListSalesHistorySchedules} from '../../sales/components/ListSalesHistorySchedules'
import {getSaleMedicalRecordsById} from '../../sales/core/_request'
import {ContractListSalesStatus} from '../../sales/components/contractModal/ListSaleStatus'

interface IPersonInfo {
  data: any
  close: any
  people: any
}

const ListSalesHistory = ({data}) => {
  const [dataSale, setDataSale] = useState()

  useEffect(() => {
    const init = async () => {
      const salesHistorySchedules = await getSaleMedicalRecordsById(Number(data))
      setDataSale(salesHistorySchedules)
    }
    init()
  }, [])

  return <ListSalesHistorySchedules salesHistorySchedules={dataSale} />
}

const SalesModal = ({data, close, people}: IPersonInfo) => {
  const {contractView} = useContractContext()
  const {setBillingData} = useBillingContext()

  useEffect(() => {
    document.body.classList.add('modal-open')
    setBillingData(data.billings)
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  function closeModal() {
    close()
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_edit_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2
                className='fw-bolder'
                style={{color: '#D31145'}}
              >{`Contrato: ${data.id} -  ${people?.name}`}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={closeModal}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <Outlet />
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <button
                          className='nav-link active'
                          id='pills-items-contract-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-items-contract'
                          type='button'
                          role='tab'
                          aria-controls='pills-items-contract'
                          aria-selected='true'
                        >
                          Items da Venda
                        </button>
                      </li>
                      <li className='nav-item'>
                        <button
                          className='nav-link'
                          id='pills-demands-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-demands'
                          type='button'
                          role='tab'
                          aria-controls='pills-demands'
                          aria-selected='true'
                        >
                          Historico Cobrança
                        </button>
                      </li>
                      <li className='nav-item'>
                        <button
                          className='nav-link'
                          id='pills-tabcontract-mod-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-tabcontract-mod'
                          type='button'
                          role='tab'
                          aria-controls='pills-tabcontract-mod'
                          aria-selected='true'
                        >
                          Contratos
                        </button>
                      </li>
                      <li className='nav-item'>
                        <button
                          className='nav-link'
                          id='pills-medical-records-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-medical-records'
                          type='button'
                          role='tab'
                          aria-controls='pills-medical-records'
                          aria-selected='true'
                        >
                          Prontuário
                        </button>
                      </li>
                      <li className='nav-item'>
                        <button
                          className='nav-link'
                          id='pills-history-schedules-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-history-schedules'
                          type='button'
                          role='tab'
                          aria-controls='pills-history-schedules'
                          aria-selected='true'
                        >
                          Histórico de agendamento
                        </button>
                      </li>
                      {data?.responsible && (
                        <li className='nav-item'>
                          <button
                            className='nav-link'
                            id='pills-responsible-tab'
                            data-bs-toggle='pill'
                            data-bs-target='#pills-responsible'
                            type='button'
                            role='tab'
                            aria-controls='pills-responsible'
                            aria-selected='true'
                          >
                            Responsável
                          </button>
                        </li>
                      )}
                      <li className='nav-item'>
                        <button
                          className='nav-link'
                          id='pills-salesHistory-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-salesHistory'
                          type='button'
                          role='tab'
                          aria-controls='pills-salesHistory'
                          aria-selected='true'
                        >
                          Histórico de Status
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='tab-content' id='pills-tabContent'>
                <div
                  className='tab-pane fade show active p-4 pt-0 pt-md-4'
                  id='pills-items-contract'
                  role='tabpanel'
                  aria-labelledby='pills-checklist-tab'
                >
                  <div className='card mb-5 mb-xl-10'>
                    <ItemSaleList itemSaleData={data?.items} />
                  </div>
                </div>
                <div
                  className='tab-pane fade p-4 pt-0 pt-md-4'
                  id='pills-demands'
                  role='tabpanel'
                  aria-labelledby='pills-checklist-tab'
                >
                  <div className='card mb-5 mb-xl-10'>
                    <BillingList />
                  </div>
                </div>
                <div
                  className='tab-pane fade p-4 pt-0 pt-md-4'
                  id='pills-tabcontract-mod'
                  role='tabpanel'
                  aria-labelledby='pills-checklist-tab'
                >
                  <div className='card mb-5 mb-xl-10'>
                    <ContractList saleId={data?.id} />
                  </div>
                </div>
                <div
                  className='tab-pane fade p-4 pt-0 pt-md-4'
                  id='pills-medical-records'
                  role='tabpanel'
                  aria-labelledby='pills-checklist-tab'
                >
                  <div className='card mb-5 mb-xl-10'>
                    <ListSalesMedicalRecords saleId={data?.id} />
                  </div>
                </div>
                <div
                  className='tab-pane fade p-4 pt-0 pt-md-4'
                  id='pills-history-schedules'
                  role='tabpanel'
                  aria-labelledby='pills-checklist-tab'
                >
                  <div className='card mb-5 mb-xl-10'>
                    <ListSalesHistory data={data?.id} />
                  </div>
                </div>
                {data?.responsible && (
                  <div
                    className='tab-pane fade p-4 pt-0 pt-md-4'
                    id='pills-responsible'
                    role='tabpanel'
                    aria-labelledby='pills-checklist-tab'
                  >
                    <div className='card mb-5 mb-xl-10'>
                      <ResponsibleDetail responsibleData={people?.responsible} />
                    </div>
                  </div>
                )}
                <div
                  className='tab-pane fade p-4 pt-0 pt-md-4'
                  id='pills-salesHistory'
                  role='tabpanel'
                  aria-labelledby='pills-salesHistory-tab'
                >
                  <div className='card mb-5 mb-xl-10'>
                    <div className='card-body py-3'>
                      <>
                        <ContractListSalesStatus history={data?.history} />
                      </>
                    </div>
                  </div>
                </div>
              </div>
              {contractView && <ContractListModal saleId={data?.id} />}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {SalesModal}
