import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'
import useAuthorization from '../../../../../hooks/useAuthorization'

const ListToolbar = () => {

    const navigate = useNavigate()
    const toCreate = () => navigate('/people/medicalConditions/create')

    const isAuthorizedToCreate = useAuthorization('medicalConditions.create')
    if(!isAuthorizedToCreate) return <></>

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {/* begin::Add user */}
            <button type='button' className='btn btn-primary' onClick={toCreate}>
                <KTIcon iconName='plus' className='fs-2' />
                Adicionar Condição Médica
            </button>
            {/* end::Add user */}
        </div>
    )
}

export { ListToolbar }
