import {FC} from 'react'
import {AdressProvider} from '../../coreGlobal/providers/AdressProvider'
import ProfileTemporarilyForm from './ProfileForm'
import ContactTemporarilyForm from './ContactForm'
import AddressTemporarilyForm from './AddressForm'

type Props = {
  formik: any
  type: number
  complete: string
  cep: any
}
const ClientTemporarilyForm: FC<Props> = ({formik, type, complete, cep}) => {
  return (
    <>
      <ProfileTemporarilyForm formik={formik} type={type} complete={complete} />
      <ContactTemporarilyForm formik={formik} complete={complete} />

      <div className='card mb-5 mb-xl-10 shadow-sm'>
        <div
          className='card-header collapsible cursor-pointer rotate'
          data-bs-toggle='collapse'
          data-bs-target='#kt_adress_card_collapsible'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Endereço:</h3>
          </div>
          <div className='card-toolbar rotate-180'>
            <i className='ki-duotone ki-down fs-1'></i>
          </div>
        </div>
        <div id='kt_adress_card_collapsible' className='collapse show'>
          <div className='card-body border-top p-9'>
            <AdressProvider>
              <AddressTemporarilyForm formik={formik} complete={complete} cep={cep} />
            </AdressProvider>
          </div>
        </div>
      </div>
    </>
  )
}
export default ClientTemporarilyForm
