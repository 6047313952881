// @ts-nocheck
import {Column} from 'react-table'
import {NameCell} from './NameCell'
import {UpdatedInCell} from './UpdatedInCell'
import {CreatedInCell} from './CreatedInCell'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {SelectionHeader} from './SelectionHeader'
import {People} from '../../core/_models'
import {DocCell} from './DocCell'
import {DescriptionCell} from './DescriptionCell'
import {IDClientCell} from './IdClientCell'
import {ClientNumberCell} from './ClientNumberCell'

const PeopleColumns: ReadonlyArray<Column<People>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='ID Cliente' className='min-w-125px' />
    ),
    id: 'clientId',
    Cell: ({...props}) => <IDClientCell people={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Número Cliente' className='min-w-125px' />
    ),
    id: 'clientNumber',
    Cell: ({...props}) => <ClientNumberCell people={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <NameCell people={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Documento' className='min-w-125px' />
    ),
    id: 'doc',
    Cell: ({...props}) => <DocCell people={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Descrição' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <DescriptionCell people={props.data[props.row.index]} />,
  },
]

export {PeopleColumns}
