import { TClientTemporarilyRequest } from "../../pages/ClientsTemporarily/update/models/_models";
import { cleanMask } from "../../utils/cleanMask";


export const buildClientTemporarilyRequest = (data: TClientTemporarilyRequest): FormData => {

    const formData = new FormData();

    if (data.id) formData.append('id', String(data.id));
    formData.append('type', '1');
    formData.append('name', data.name);
    if (data.preferredName) formData.append('preferredName', data.preferredName);
    if (data.email) formData.append('email', data.email);
    formData.append('document', cleanMask(data.document));
    if (data.gender) formData.append('gender', data.gender);
    if (data.birthDate) formData.append('birthDate', data.birthDate);
    if (data.rg) formData.append('rg', data.rg);
    if (data.job) formData.append('job', data.job);
    if (data.nationality) formData.append('nationality', String(data.nationality));

    if (data.contacts) {
        data.contacts.forEach((contact, index) => {
            if (contact.id) formData.append(`contacts[${index}][id]`, String(contact.id));
            formData.append(`contacts[${index}][type]`, String(contact.type));
            formData.append(`contacts[${index}][phone]`, cleanMask(contact.phone));
        });
    }

    formData.append('reduceTime', String(data.reduceTime ? 1 : 0));
    formData.append('hideAvatar', String(data.hideAvatar ? 1 : 0));
    

    if (data.addresses) {
        data.addresses.forEach((address, index) => {
            if (address.id) formData.append(`addresses[${index}][id]`, String(address.id));
            formData.append(`addresses[${index}][isMain]`, '1');
            formData.append(`addresses[${index}][zipCode]`, address.zipCode);
            formData.append(`addresses[${index}][street]`, address.street);
            formData.append(`addresses[${index}][number]`, String(address.number));
            formData.append(`addresses[${index}][complement]`, String(address.complement));
            formData.append(`addresses[${index}][neighborhood]`, address.neighborhood);
            formData.append(`addresses[${index}][city]`, String(address.city));
        });
    }
    if (data.oldClientId) formData.append('oldClientId', String(data.oldClientId));
    return formData;    
}
