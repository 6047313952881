import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { IPersonResponse, TPersonRequest } from '../../coreGlobal/models/person';
import { KTIcon } from '../../_metronic/helpers';
import PeopleForm from './PeopleForm';
import { cepMask } from '../../utils/cepMask';
import { PersonValidSchema } from '../../coreGlobal/validation/PersonVali';
import { phoneMask } from "../../utils/phoneMask";
import Swal from 'sweetalert2';
import { buildPersonRequest } from '../../coreGlobal/buildPersonRequest';
import {createPerson} from '../../coreGlobal/_resquest'
import { usePeople } from '../../coreGlobal/providers/PersonProvider';

interface IPersonInfo {
  person: IPersonResponse
  provider: any
  index: number
  close: any
}
const PersonEditModal = ({ person, provider, index, close }: IPersonInfo) => {
   
  const { setPerson, removePerson } = provider()
  const { showCompleteContactsModal, setShowEditPersonModal } = usePeople();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  function closeModal() {
    close();
    if (showCompleteContactsModal) {
      removePerson();
    }
  }

  const addresses = person.addresses?.map((value) => ({
    id: value.id,
    isMain: value.main,
    zipCode: cepMask(value.zipCode),
    street: value.street,
    number: value.number,
    neighborhood: value.neighborhood,
    city: value.city.id,
    state: value.city.state.id,
    ...(value.complement && { complement: value.complement }),
  }))

  const banksData = person.bank?.map((value) => ({
    id: value.id,
    isMain: value.main,
    accountType: value.accountType.id,
    bank: value.bank.id,
    agency: value.agency,
    agencyDv: value.agencyDv,
    account: value.account,
    accountDv: value.accountDv,
  }))

  const MedicalConditionsData = person.medicalConditions?.map((value: any, index): any => ({
    id: value.id,
    name: value.name,
    status:value.status,
    filePath: value.clientMedicalCondition.filePath,
    documentAuthorized: value.clientMedicalCondition.filePath,
    authorized: value.clientMedicalCondition.authorized ? 1 : 0,
    observation: value.clientMedicalCondition.observation,
    createdBy: value.clientMedicalCondition.createdBy.name,
    updatedBy: value.clientMedicalCondition.updatedBy.name
  }))

  const contactsData = person.contacts?.map((value) => ({
    id: value.id,
    type: value.typeId,
    phone: phoneMask(value.phone),
  }))

  const ObservationsData = person.observations?.map((value) => ({
    id: value.id,
    observation: value.observation,
    user: value.user,
    createdAt: value.createdAt,
    updatedAt: value.updatedAt,
  }))

  const initialValues: TPersonRequest = {
    id: person.id,
    type: person.type,
    name: person.name,
    preferredName: person?.physicalPerson?.preferredName || '',
    email: person.email ?? '',
    document: String(person.physicalPerson?.document),
    birthDate: person.physicalPerson?.birthDate || '',
    gender: person.physicalPerson?.gender || '',
    rg: person.physicalPerson?.rg || '',
    job: person.physicalPerson?.job || '',
    nationality: person.physicalPerson?.nationality?.id || 22,
    contacts: contactsData.length > 0 ? contactsData : [{ type: 0, phone: ""}],
    addresses: addresses,
    bankData: banksData,
    medicalConditions: MedicalConditionsData || [],
    observations: ObservationsData || [],
    reduceTime: person.physicalPerson?.reduceTime || true,
    hideAvatar: person.physicalPerson?.hideAvatar || false
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(true),
    validateOnMount: true,
    onSubmit: async (values) => {
      const valuesFilter = buildPersonRequest(values)
      await submit(valuesFilter);
    },
  });

  const submit = async (req: FormData) => {
    await Swal.fire({
      title: 'Deseja atualizar o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data = await createPerson(req)
          setPerson(data, index)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          closeModal()
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }
  
  return (
    <form id='personFormModal' className='form' noValidate>
      <div className='modal fade show d-block' id='kt_modal_edit_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-950px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{showCompleteContactsModal ? 'Complete' : 'Edite'} os Dados</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' data-kt-users-modal-action='close'
                onClick={closeModal} style={{ cursor: 'pointer' }}>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <div className='row mb-6'>
                <b className="fw-bold d-block text-dark">Cliente: <span
                  className="fw-bold text-danger">{person?.name}</span></b>
              </div>
              <PeopleForm formik={formik} type={person.type} complete={'required'} mode='UPDATE'/>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type="reset" className="btn btn-secondary me-2" onClick={closeModal}>Cancelar</button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={(e) => { e.preventDefault(); formik.handleSubmit() }}
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </form>
  );
};

export { PersonEditModal };