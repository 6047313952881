import axios, { AxiosResponse } from 'axios'
import { TCategoryRequest } from '../../../coreGlobal/models/products/category'
import { CategoriesQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const CATEGORY_URL = `${API_URL}/products/categories`

const updateCategory = (categoryId: string | number, data: TCategoryRequest) => {
    return axios
      .put(`${CATEGORY_URL}/${categoryId}`, data)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}

const getCategories = (query: string): Promise<CategoriesQueryResponse> => {
  return axios
      .get(`${CATEGORY_URL}?${query}`)
      .then((d: AxiosResponse<CategoriesQueryResponse>) => d.data)

}

const getCategoryById = (categoryId: string | number) => {
  return axios
    .get(`${CATEGORY_URL}/${categoryId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const createCategory = (data: TCategoryRequest) => {
  return axios
    .post(`${CATEGORY_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}


export { updateCategory, getCategories, getCategoryById, createCategory };