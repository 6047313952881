/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { User } from '../../core/_models'
import { useNavigate } from 'react-router-dom'
import { cpfMask } from '../../../../../utils/cpfMask'

type Props = {

    user: User,

}

const StatusCell: FC<Props> = ({ user }) => {

    const id = String(user.id) as string;

    const navigate = useNavigate()

   const handleToShow = () => navigate(`/users/list/${id}`)

   return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className='text-gray-800 text-hover-primary mb-1' onClick={()=> handleToShow()}>
                {user.active ? "Ativo" : "Inativo"}
            </span>
        </div>
    </div>
}

export { StatusCell  }
