import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { resetPassword } from "../../coreGlobal/_resquest";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangePassWordForm } from "./components/ChangePassWordForm";
import { passWordSchema } from "../resetPassWord/core/validation/PassWordVali";

interface PasswordResetRequest {
    token: string,
    email: string,
    password: string,
    newPassWord: string
}

export const ChangePasswordPage = () => {
    const locationUrl = useLocation()
    const queryParams = new URLSearchParams(locationUrl.search);

    const token = queryParams.get('token');
    const email = queryParams.get('email');

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [passWordInitialValues] = useState({
        token: '',
        email: '',
        password: '',
        newPassWord: ''
    })

    useEffect(() => {
        if (loading) {
            Swal.fire({
                position: 'center',
                icon: 'info',
                text: "Alterando a senha...",
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }
    }, [loading])

    useEffect(() => {
        if (email && token) {
            formik.setValues({
                token: token,
                email: email,
                password: '',
                newPassWord: ''
            });
        }
    }, [])

    const formik = useFormik({
        initialValues: passWordInitialValues,
        validationSchema: passWordSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        }
    })

    const handleSubmit = async (values: PasswordResetRequest) => {
        await Swal.fire({
            title: 'Deseja alterar a senha ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true)
                    await resetPassword(values.password, values.newPassWord, values.token, values.email)
                    setLoading(false)
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Salvo com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    navigate('/auth')
                } catch (error: any) {
                    setLoading(false)
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                            errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        await Swal.fire({
                            icon: 'error',
                            title: 'Ops! Revise seu formulário e tente novamente!',
                            html: errorText
                        });
                    } else {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.message,
                            timer: 2000
                        })
                    }
                }
            }
        })

    };

    return (
        <ChangePassWordForm formik={formik} />
    )
}