import { IPersonResponse } from "./person";

export type IUserResponse = {
  name: string
  cpf: string
  email: string
  phone: string
  emailVerified: string,
  person: IPersonResponse
};

export type TUserResquest = {
  name: string
  cpfUser: string
  email: string
  phoneUser: string
  person: IPersonResponse
  password?: string
  franchises: Array<number>
  roles: Array<number>
  active: boolean
};

export type TEditUserRequest = {
  name: string
  cpf: string
  email: string
  phone: string
  person?: number
  password?: string
  franchises: Array<number>
  roles: Array<number>
  active: boolean
};
export type TUser = {
  id: number
  personId: number
  name: string
  cpf: string
  email: string
  phone: string
  firstLogin: boolean
  createdAt: string
  updatedAt: string
}
export type TCreateUserRequest = {
  name: string
  cpf: string
  email: string
  phone: string
  person?: number
  password?: string
  franchises: Array<number>
  roles: Array<number>
  active: boolean
};

export const userInitValues: any = {
  type: 2,
  name: '',
  document: '',
  franchiseEmail: '',
  socialReason: '',
  fantasyName: '',
  email: '',
  addresses: [{
    zipCode: '',
    street: '',
    neighborhood: '',
    number: ''
  }],
  active: true
}