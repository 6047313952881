import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { useAuth } from '../../../../app/modules/auth'

const moment = require('moment')
dayjs.locale('pt-br')

const ScheduleMarking = ({
  time,
  formik,
  UpdatedTime,
  clearDateHour,
  dateQueryInit,
  dateQueryEnd,
  setDateQueryInit,
  setDateQueryEnd,
  client,
  fullSchedule,
}) => {
  const { currentFranchise } = useAuth()

  const [selectedHour, setSelectedHour] = useState<{ day: string | null; hour: string | null }>({
    day: null,
    hour: null,
  })
  const [timeArray, setTimeArray] = useState<any>([])

  useEffect(() => {
    if (clearDateHour === true) {
      setSelectedHour({ day: null, hour: null })
    }
    clearDateHour = false
  }, [clearDateHour])

  useEffect(() => {
    if (time && !fullSchedule) {
      const times = Object.keys(time).map((key) => ({
        date: key,
        hours: Object.keys(time[key]).map((hour) => parseInt(hour)),
        NumberDay: moment(key).format('DD'),
        WeekDay: moment(key).format('ddd'),
      }))
      times.forEach((dayEntry) => {
        dayEntry.hours.sort((a, b) => a - b)
      })
      setTimeArray(times)
      setDateQueryInit(dayjs(times[0].date))
      setDateQueryEnd(dayjs(times[times.length - 1].date))
    }
  }, [time])

  const nextWeek = () => {
    const firstDateFutureWeek = dayjs(dateQueryInit)
      .add(7, 'days')
      .format(`YYYY-MM-DD ${currentFranchise?.startTime}:ss`)
    const lastDateFutureWeek = dayjs(firstDateFutureWeek)
      .add(6, 'days')
      .format(`YYYY-MM-DD ${currentFranchise?.endTime}:ss`)

    const arrayTime = [
      {
        init: firstDateFutureWeek,
        end: lastDateFutureWeek,
      },
    ]

    setDateQueryInit(firstDateFutureWeek)
    setDateQueryEnd(lastDateFutureWeek)
    UpdatedTime(arrayTime)

    setSelectedHour({ day: null, hour: null })
  }

  const prevWeek = () => {
    const firstDateLastWeek = dayjs(dateQueryInit)
      .subtract(7, 'days')
      .format(`YYYY-MM-DD ${currentFranchise?.startTime}:ss`)
    const lastDateLastWeek = dayjs(firstDateLastWeek)
      .add(6, 'days')
      .format(`YYYY-MM-DD ${currentFranchise?.endTime}:ss`)

    const arrayTime = [
      {
        init: firstDateLastWeek,
        end: lastDateLastWeek,
      },
    ]

    UpdatedTime(arrayTime)
    setDateQueryInit(firstDateLastWeek)
    setDateQueryEnd(lastDateLastWeek)

    setSelectedHour({ day: null, hour: null })
  }

  const handleHourClick = (hour: string, day: string, date: string) => {
    setSelectedHour({ day, hour })
    formik.setFieldValue('dateHour', `${date} ${hour}`)
  }

  return (
    <>
      <div className='row w-100' style={{ justifyContent: 'center' }}>
        <div className='row border border-white'>
          <div className='col-12 mb-1 fw-bold d-block'>
            <span className='text-danger'> Cliente:</span> {client?.name}
          </div>
        </div>
        <div className='row border border-white mb-2'>
          <div className='col'>
            <div className='d-flex justify-content-between align-items-center mt-3 mb-2'>
              <button
                type='button'
                className='btn btn-danger btn-sm'
                onClick={() => prevWeek()}
              >
                <i className='bi bi-arrow-left'></i>
              </button>
              <div className='border border-white'>
                <div className='fw-bold d-block '>
                  <h1 className='text-center fw-bold text-danger'>
                    {dayjs(dateQueryInit).format('DD [de] MMM - YYYY')} /{' '}
                    {dayjs(dateQueryEnd).format('DD [de] MMM - YYYY')}
                  </h1>
                  <h4 className='text-center'>Selecione o dia de preferência para o atendimento.</h4>
                </div>
              </div>
              <button
                type='button'
                className='btn btn-danger btn-sm'
                onClick={() => nextWeek()}
              >
                <i className='bi bi-arrow-right'></i>
              </button>
            </div>
            <div className='table-responsive mh-350px'>
              <table className='table' >
                <thead style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}>

                  <tr className="table-active">
                    {timeArray.map((dayEntry, dayIndex) => (
                      <td key={dayIndex} className='text-center' style={{ verticalAlign: 'top' }}>
                        <div>
                          <h4 className='fw-bold'>{dayEntry.WeekDay}</h4>
                          <p className='fw-bold'>{dayEntry.NumberDay}</p>
                        </div>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {timeArray.map((dayEntry, dayIndex) => (
                      <td key={dayIndex} className='text-center' style={{ verticalAlign: 'top' }}>
                        <div>
                          {dayEntry.hours.map((hour, index) => (
                            <div key={index} style={{ marginBottom: '5px' }}>
                              <button
                                type='button'
                                style={{
                                  backgroundColor:
                                    selectedHour.day === dayEntry.NumberDay &&
                                      Number(selectedHour.hour) === hour
                                      ? '#D31145'
                                      : 'white',
                                  borderColor: 'white',
                                  color:
                                    selectedHour.day === dayEntry.NumberDay &&
                                      Number(selectedHour.hour) === hour
                                      ? 'white'
                                      : 'black',
                                  borderRadius: '10px',
                                  border: 'transparent',
                                  padding: '4px',
                                  minWidth: '60px',
                                }}
                                onClick={() =>
                                  handleHourClick(
                                    String(hour).length >= 2 ? String(hour) : String('0' + hour),
                                    dayEntry.NumberDay,
                                    dayEntry.date
                                  )
                                }
                              >
                                {String(hour).length >= 2 ? String(hour) : String('0' + hour)}
                              </button>
                            </div>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ScheduleMarking }
