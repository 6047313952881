import moment from 'moment'
import { phoneMask } from '../../../chooseFranchise/utils/mask'
import { useEffect, useState } from 'react'
import { getSchedulesMachines } from '../../core/_request'
import { PersonEditModal } from '../../../../components/Person/PersonEditModal'
import { useScheduleCart } from '../../core/ClientScheduleProvider'
import Select from 'react-select'
import { useScheduleModal } from '../../core/ScheduleProvider'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getStatusClient } from '../../utils/getStatusClient'
import useAuthorization from '../../../../hooks/useAuthorization'
import { LoadingSchedules } from '../alerts/loadingSchedules'
import { useAuth } from '../../../../app/modules/auth'
import dayjs from 'dayjs'

interface IClientInfo {
  client: any
  formik: any
  stepper: number
  clearInfo: boolean
  setProductInadimplente: any
  setDateQueryInit: any
  setDateQueryEnd: any
}

type TItem = {
  id: number
  discount: string
  product: {
    id: number
    category_id: number
    name: string
    gender: string
    price: string
    packagePrice: string
    sessionTime: string
    active: boolean
    minDaysBetweenSessions: string
    defaultMaxDiscount: string
  }
  price: string
  saleId: number
  sessions: number
  sessionsRealized: number
  totalPirce: string
  type: string
}
export const ClientScheduleInfo = ({
  client,
  formik,
  stepper,
  clearInfo,
  setProductInadimplente,
  setDateQueryInit,
  setDateQueryEnd

}: IClientInfo) => {
  const { showEditPersonModal, setShowEditPersonModal } = useScheduleCart()
  const clientStatus = client?.lastClientHistory?.status_id
  const {
    categories,
    selectsCategories,
    setSelectsCategories,
    products,
    selectsProducts,
    getAvaliableItens,
    setSelectsProducts,
    evaluationProducts,
    getEvaluationProducts,
    loading,
  } = useScheduleModal()

  const [machines, setMachines] = useState<any>()
  const [selectedProducts, setSelectedProducts] = useState<any>([])
  const [selectedProduct, setSelectedProduct] = useState<any>([])
  const [productCheck, setProductCheck] = useState<any>()
  const [hideProduct, setHideProduct] = useState<any>(false)
  const [hideMachine, setHideMachine] = useState<any>(false)
  const { currentFranchise } = useAuth()

  useEffect(() => {
    formik.setFieldValue('products', selectedProducts, true)
    formik.setFieldValue('product_id', selectedProduct, true)
    updateMachine(undefined) // Chamando a função updateMachine sempre que selectedProducts mudar

    const hasContractInvaliable =
      products
        ?.filter((product) => {
          return selectedProducts.includes(product.id)
        })
        .filter((product) => {
          return product.sale.status === 'Inadimplente'
        }).length !== 0
    setProductInadimplente(hasContractInvaliable)
  }, [selectedProducts])

  useEffect(() => {
    if (clearInfo) {
      formik.setFieldValue('products', [], true)
      formik.setFieldValue('product_id', [], true)
      formik.setFieldValue('machine', undefined, true)
      formik.setFieldValue('evaluation', false, true)
      formik.setFieldValue('fitting', 0, true)
      setSelectedProduct([])
      setSelectedProducts([])
      setSelectsProducts([])
      setProductCheck(undefined)
      setHideProduct(false)
      setHideMachine(false)
      setSelectsCategories({ value: '', label: 'Selecione uma categoria' })

      const firstDateLastWeek = dayjs()
        .format(`YYYY-MM-DD ${currentFranchise?.startTime}:ss`)
      const lastDateLastWeek = dayjs(firstDateLastWeek)
        .add(6, 'days')
        .format(`YYYY-MM-DD ${currentFranchise?.endTime}:ss`)

      setDateQueryInit(firstDateLastWeek)
      setDateQueryEnd(lastDateLastWeek)
    }
  }, [clearInfo])

  useEffect(() => {
    if (client) {
      getAvaliableItens(client.id)
    }
  }, [client])

  useEffect(() => {
    formik.setFieldValue('fitting', 0, true)
  }, [])

  // Pegar o produto selecionado e ver se ele estava selecionado ou nao
  const handleProductChange = (productsId, productId) => {
    setSelectedProducts((prevSelectedProducts) => {
      const index = prevSelectedProducts.indexOf(productsId)
      if (index === -1) {
        return [...prevSelectedProducts, productsId]
      } else {
        const updatedProducts = [...prevSelectedProducts]
        updatedProducts.splice(index, 1)
        return updatedProducts
      }
    })

    setSelectedProduct((prevSelectedProduct) => {
      const index = prevSelectedProduct.indexOf(productId)
      if (index === -1) {
        return [...prevSelectedProduct, productId]
      } else {
        const updatedProduct = [...prevSelectedProduct]
        updatedProduct.splice(index, 1)
        return updatedProduct
      }
    })
  }

  // Funções para manipular maquinas
  const getMachines = async (productId: string) => {
    try {
      if (productId != '') {
        const machinesData = await getSchedulesMachines(productId)
        setMachines(machinesData)
      }
    } catch (error) {
      console.error('Erro ao obter os dados das máquinas:', error)
    }
  }

  const updateMachine = async (data: string | undefined) => {
    const productArray = [selectedProduct]
    const productID = productArray.map((data) => data)
    if (productArray) {
      await getMachines(String(productID))
    } else {
      await getMachines(String(data))
    }
  }

  const handleMachineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMachineId = parseInt(event.target.value)
    formik.setFieldValue('machine', selectedMachineId, true)
  }

  const filterProduct = (category_id) => {
    const filterItems = products?.filter((item) => {
      return item.product.category_id === category_id
    })
    setProductCheck(filterItems)
  }

  const verifyDisableItem = (item: TItem) => {
    const contractAvaliable = products?.filter((product) => {
      return product.sale.status === 'Inadimplente'
    })
    const filteredProducts = contractAvaliable?.some((contract) => item.saleId === contract.sale.id)
    if (filteredProducts) {
      return true
    }

    return false
  }

  const changeByCategory = (category_id) => {
    formik.setFieldValue('category_id', category_id, true)
    if (category_id == 2 || category_id == 4) {
      if (category_id === 4) {
        getEvaluationProducts()
      }
      setHideProduct(true)
      setHideMachine(false)
      setProductCheck(null)
    } else {
      setHideProduct(false)
      setHideMachine(true)
      setProductCheck(null)
    }
  }

  const setEvaluationValue = (data: boolean) => {
    formik.setFieldValue('evaluation', data, true)
  }

  const clearData = () => {
    formik.setFieldValue('products', [], true)
    formik.setFieldValue('product_id', [], true)
    formik.setFieldValue('machine', undefined, true)
    setSelectedProduct([])
    setSelectedProducts([])
    setSelectsProducts([])
  }

  const clientSelected = getStatusClient(clientStatus)

  const isAuthorizedToChangeFit = useAuthorization('schedules.fit_in_appointment')
  const isAuthorizedToViewPerson = useAuthorization('persons.view')
  const isAuthorizedToUpdatePerson = useAuthorization('persons.view')

  return (
    <>
      {loading && <LoadingSchedules />}
      <div className="container">
        <div className="row mb-6 mt-4" style={{ justifyContent: 'right' }}>
          <div className='col-md-6 col-sm-12'>
            <label htmlFor='' className='text-group'>
              <b>Nome</b>
            </label>
            <input
              type='disable'
              className='form-control form-control-lg form-control-solid'
              value={
                client?.physicalPerson?.preferredName
                  ? client?.physicalPerson?.preferredName
                  : client?.name
              }
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <label htmlFor='' className='text-group'>
              <b>CPF</b>
            </label>
            <input
              type='disable'
              className='form-control form-control-lg form-control-solid'
              value={client?.physicalPerson.document}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            {isAuthorizedToUpdatePerson && (
              <span
                role='button'
                onClick={() => setShowEditPersonModal(true)}
                className='ms-2 btn text-white bg-danger w-auto, p-2'
                style={{ fontSize: 12 }}
              >
                Editar Cliente
              </span>
            )}
            {isAuthorizedToViewPerson && (
              <span
                role='button'
                onClick={() => {
                  window.open(toAbsoluteUrl(`/people/list/${client.id}`), '_blank')
                }}
                className='ms-2 btn text-white bg-danger w-auto, p-2'
                style={{ fontSize: 12 }}
              >
                Dados do cliente
              </span>
            )}
            <span className='fw-bold d-block text-danger mb-3 mt-6'>
              {client?.physicalPerson?.preferredName
                ? client?.physicalPerson?.preferredName
                : client?.name}
              , cliente desde {moment(client?.createdAt).format('DD/MM/YYYY')}
            </span>
            <span className='fw-bold d-block text-dark mb-1' style={{ fontSize: 12 }}>
              Status:
              <span className={clientSelected?.color}>{clientSelected?.name}</span>
            </span>
            <span className='fw-bold d-block text-dark mb-1'>
              Idade:
              <span className='fw-normal text-dark ms-1'>{client?.physicalPerson?.age}</span>
            </span>
            <span className='fw-bold d-block text-dark mb-1'>
              Aniversário:
              <span className='fw-normal text-dark ms-1'>
                {moment(client?.physicalPerson?.birthDate).format('DD/MM/YYYY') || '-'}
              </span>
            </span>
            <span className='fw-bold d-block text-dark mb-1'>
              Profissão:
              <span className='fw-normal text-dark ms-1'>
                {client?.physicalPerson?.job || '-'}
              </span>
            </span>
            <span className='fw-bold d-block text-dark mb-1'>
              E-mail:
              <span className='fw-normal text-dark ms-1'>{client?.email || '-'}</span>
            </span>
            <span className='fw-bold d-block text-dark mb-1'>
              Telefone:
              <span className='fw-normal text-dark ms-1'>
                {phoneMask(client?.contacts[0]?.phone) || '-'}
              </span>
            </span>
          </div>
          <div className='col-md-6 col-sm-12'>
            {isAuthorizedToChangeFit && (
              <>
                <label className='col col-form-label fw-bold fs-6'>
                  <span className='required'>Encaixe</span>
                </label>
                <div className='fv-row mb-6'>
                  <select
                    className='form-select'
                    value={formik.values.fitting}
                    onChange={(e) => {
                      formik.setFieldValue('fitting', e.target.value, true)
                    }}
                  >
                    <option value='0'>Não</option>
                    <option value='1'>Sim</option>
                  </select>
                </div>
              </>
            )}
            <label className='col col-form-label fw-bold fs-6'>
              <span className='required'>Categoria</span>
            </label>
            <Select
              className='react-select-styled react-select-solid mb-6'
              classNamePrefix='react-select'
              placeholder='Selecione uma categoria'
              onChange={(option) => {
                setSelectsCategories(option!)
                if (String(option?.value) === '1') {
                  clearData()
                  setEvaluationValue(false)
                  changeByCategory(1)
                  filterProduct(1)
                } else if (String(option?.value) === '2') {
                  clearData()
                  setEvaluationValue(false)
                  changeByCategory(2)
                  filterProduct(2)
                } else if (String(option?.value) === '4') {
                  clearData()
                  setEvaluationValue(true)
                  changeByCategory(4)
                }
              }}
              options={categories}
              value={selectsCategories!}
            />

            {productCheck && (
              <div className='mb-6'>
                <h4>Produtos</h4>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='col-1 fw-bold'></th>
                      <th className='col-2 fw-bold'>Contrato</th>
                      <th className='col-7 fw-bold text-center'>Área</th>
                      <th className='col-2 fw-bold'>Sessão</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productCheck?.map((item) => (
                      <tr key={item.product?.id}>
                        <td>
                          <input
                            type='checkbox'
                            id={`flexCheckboxDefault${item.id}`}
                            checked={selectedProducts.includes(item.id)}
                            onChange={() => {
                              formik.setFieldValue(
                                'category_product_id',
                                item.product.category_id,
                                true
                              )
                              handleProductChange(item.id, item.product.id)
                            }}
                          />
                        </td>
                        <td
                          className='text-center'
                          style={{ color: verifyDisableItem(item) ? '#A9A9A9' : `` }}
                        >
                          {item.saleId}
                        </td>
                        <td
                          className='text-center'
                          style={{ color: verifyDisableItem(item) ? '#A9A9A9' : '' }}
                        >
                          <label
                            className='ms-2 text-center'
                            htmlFor={`flexCheckboxDefault${item.id}`}
                          >
                            {item.product?.name}
                          </label>
                        </td>
                        <td
                          className='text-center'
                          style={{ color: verifyDisableItem(item) ? '#A9A9A9' : '' }}
                        >
                          {item.sessionsRealized + 1} / {item.sessions}{' '}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {hideProduct && !productCheck && (
              <div className='mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Produtos</span>
                </label>
                <div className='fv-row'>
                  <Select
                    isMulti
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    placeholder='Selecione um produto'
                    onChange={(option) => {
                      const valores = option.map((item) => item.value)
                      setSelectsProducts(option)
                      formik.setFieldValue('product_id', valores, true)
                      formik.setFieldValue('products', valores, true)

                      updateMachine(undefined)
                    }}
                    options={evaluationProducts}
                    value={selectsProducts}
                  />
                </div>
              </div>
            )}

            {hideMachine && machines && (
              <div>
                <h4>Máquinas</h4>
                <select
                  className='form-select mb-6'
                  onChange={handleMachineChange}
                  value={formik.values.machine || ''}
                >
                  <option value=''>Selecione uma Máquina</option>
                  {machines?.map((data: { id: number | string; name: string }) => (
                    <option key={data.id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
      {showEditPersonModal && (
        <PersonEditModal
          person={client}
          provider={useScheduleCart}
          index={0}
          close={() => setShowEditPersonModal(false)}
        />
      )}
    </>
  )
}
