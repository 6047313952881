import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const SALE_URL = `${API_URL}/sales`

const sendLinkChargeToCustomer = (saleId) => {
    return axios.post(`${SALE_URL}/${saleId}/send`)
}

export {
    sendLinkChargeToCustomer
}