import axios, {AxiosResponse} from 'axios'
import {SalesIndexQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const SALE_URL = `${API_URL}/sales`

const getSales = (query: string): Promise<SalesIndexQueryResponse> => {
    return axios
        .get(`${SALE_URL}?${query}&include=client.physicalPerson`)
        .then((d: AxiosResponse<SalesIndexQueryResponse>) => d.data)
}

export {getSales};