import {FC, useState, createContext, useContext, useEffect} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {ISelect, RoomList} from '../../../coreGlobal/models/default'
import {Option} from "react-multi-select-component";
import {getClientAvailableItems, getProducts} from '../../rooms/core/_request';
import {getCategories} from '../../products/core/_request';
import {MultiValue} from 'react-select';
import { IProductAvaliable } from '../../../coreGlobal/models/schedules/scheduleAvaliable';


interface ScheduleModalContext {
  schedule: RoomList | null
  setSchedule: React.Dispatch<React.SetStateAction<any>>

  categories: ISelect[] | undefined
  selectsCategories: ISelect | undefined
  setSelectsCategories: React.Dispatch<React.SetStateAction<ISelect | undefined>>

  getAvaliableItens: (category: string) => void
  getCategories: () => void
  getEvaluationProducts: () => void

  products: IProductAvaliable[] | undefined
  setProducts: React.Dispatch<React.SetStateAction<IProductAvaliable[] | undefined>>

  evaluationProducts: ISelect[]
  setEvaluationProducts: React.Dispatch<React.SetStateAction<ISelect[]>>

  selectsProducts: MultiValue<Option>
  setSelectsProducts: React.Dispatch<React.SetStateAction<MultiValue<Option>>>

  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>

  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  loadingPage: boolean
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>

}

const ScheduleContext = createContext<ScheduleModalContext>({} as ScheduleModalContext)

const ScheduleModalProvider: FC<WithChildren> = ({children}) => {
  const initialSelectValue = [{value: '', label: ''}];
  const [schedule, setSchedule] = useState<RoomList | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectsCategories, setSelectsCategories] = useState<ISelect>();
  const [products, setProducts] = useState<IProductAvaliable[] | undefined>();
  const [evaluationProducts, setEvaluationProducts] = useState<ISelect[]>(initialSelectValue);
  const [selectsProducts, setSelectsProducts] = useState<MultiValue<Option>>([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const categories: ISelect[] = [
    {
      value: '1',
      label: 'Depilação'
    },
    {
      value: '2',
      label: 'Estética'
    },
    {
      value: '4',
      label: 'Avaliação'
    },
  ]

  const getAvaliableItens = async (peopleId: string) => {
    const data = await getClientAvailableItems(peopleId)
    setProducts(data)
  }

  const getEvaluationProducts = async () => {
    setLoading(true)
    const data = await getProducts('4')
    setLoading(false)
    setEvaluationProducts(data!)
  }

  return (
    <ScheduleContext.Provider
      value={{
        schedule,
        setSchedule,

        categories,
        selectsCategories,
        setSelectsCategories,
        

        showModal,
        setShowModal,
        getAvaliableItens,
        getEvaluationProducts,
        getCategories,

        evaluationProducts,
        setEvaluationProducts, 

        products,
        setProducts,

        selectsProducts,
        setSelectsProducts,

        loading,
        setLoading,
        loadingPage,
        setLoadingPage
      }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}

const useScheduleModal = () => useContext(ScheduleContext)

export {ScheduleModalProvider, useScheduleModal}
