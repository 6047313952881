import { useEffect, useState } from 'react';
import { getAuth, useAuth } from '../app/modules/auth';
import { isAuthorizedUser } from '../app/routing/utils/permissions';

const useAuthorization = (requiredPermission) => {

    const { currentUser } = useAuth();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const authorized = isAuthorizedUser(currentUser, requiredPermission);
        setIsAuthorized(authorized);
    }, [requiredPermission]);

    return isAuthorized;
};

export default useAuthorization;
