import axios, { AxiosResponse } from 'axios'
import { TCreditCard, TPayment } from './creditCardPayment'
import { Response, SimpleResponse } from '../../../../_metronic/helpers'

import { TViaCepResponse } from '../../../../coreGlobal/models/Address/viaCep'
import { TStateResponse } from '../../../../coreGlobal/models/Address/address'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const PAYMENT_URL = `${API_URL}/sales`
const STATE_URL = `${API_URL}/state`
const GENERATE_PAYMENT_URL = `${API_URL}/payments`
const FIND_CEP_URL = `${API_URL}/findCep`

const getSaleById = (paymentId: string) => {
  return axios
    .get(`${PAYMENT_URL}/${paymentId}?include=items.product`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}


const generatePayment = (payment: TPayment): Promise<TCreditCard | undefined> => {
  return axios
    .post(GENERATE_PAYMENT_URL, payment)
    .then((response: AxiosResponse<SimpleResponse<TCreditCard>>) => response.data)
    .then((response: SimpleResponse<TCreditCard>) => response.data)
}

const getCepByViaCep = (cep: string): Promise<TViaCepResponse> => {
  return axios
    .get(`${FIND_CEP_URL}/${cep}`)
    .then((response: any) => response.data)
}


const getStatesAndCities = (): Promise<TStateResponse[] | undefined> => {
  return axios
    .get(`${STATE_URL}?include=cities&per_page=27`)
    .then((response: AxiosResponse<Response<TStateResponse[]>>) => response.data)
    .then((response: Response<TStateResponse[]>) => response.data)
}

export { getSaleById, generatePayment, getCepByViaCep, getStatesAndCities };