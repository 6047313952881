export const getStatusClient = (status) => {
    const info = {
      1: { 
        name: 'Ativo',
        color: 'badge badge-success ms-1',
        colorMedical: 'bg-success'
      },
      2: { 
        name: 'Restrito',
        color: 'badge badge-warning ms-1',
        colorMedical: 'bg-warning'
       },
      3: { 
        name: 'Outros',
        color: 'badge badge-danger ms-1',
        colorMedical: 'bg-danger'
       },
    };
    return info[status];
  };