import axios, {AxiosResponse} from 'axios'
import {ClientTemporarilyQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const CLIENT_TEMPORARILY_URL = `${API_URL}/failed-clients`

const listClientTemporarily = (query: string): Promise<ClientTemporarilyQueryResponse> => {
    return axios
    .get(`${CLIENT_TEMPORARILY_URL}?${query}`)
    .then((d: AxiosResponse<ClientTemporarilyQueryResponse>) => d.data)
}
export {listClientTemporarily};