// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-swal-bg {
    background-color: #f1416c !important;
  }
  
  .custom-swal-title {
    color: white !important;
    font-size: 1.2rem !important;
  }
  
  div:where(.swal2-container) div:where(.swal2-loader) {
    border-color: #ffffff rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) !important; /* Cor do spinner */
  }`, "",{"version":3,"sources":["webpack://./src/utils/loading/loading.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;EACtC;;EAEA;IACE,uBAAuB;IACvB,4BAA4B;EAC9B;;EAEA;IACE,0EAA0E,EAAE,mBAAmB;EACjG","sourcesContent":[".custom-swal-bg {\n    background-color: #f1416c !important;\n  }\n  \n  .custom-swal-title {\n    color: white !important;\n    font-size: 1.2rem !important;\n  }\n  \n  div:where(.swal2-container) div:where(.swal2-loader) {\n    border-color: #ffffff rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) !important; /* Cor do spinner */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
