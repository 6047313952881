import { useEffect, useState } from 'react'
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { formatDateCrediCard } from '../../payment/utils/mask'
import { TCity, TStateResponse } from '../../../../coreGlobal/models/Address/address'
import { generatePayment, getCepByViaCep, getStatesAndCities } from '../../payment/core/_request'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { TCreditCardForm } from '../../payment/core/creditCardPayment'
import { creditCardSchema } from '../../payment/core/Validation/CreditCardVali'
import { buildCreditCardRequest } from '../../payment/core/buildCreditCardRequest'
import { Billing } from '../../core/_models'
import { formatMask, formatMonetaryToBr } from '../../../../utils/formatMonetaryToBr'
import "./styles/creditcard.scss"

const defaultCreditCardData = {
    cardNumber: '',
    expiry: '',
    cvv: '',
    holder: '',
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: ''
}

interface IPaymentAccordion {
    billing: Billing,
    saleId: string
}

const CreditCardAccordion = ({ billing, saleId }: IPaymentAccordion) => {
    const [formData, setFormData] = useState<TCreditCardForm>(defaultCreditCardData)
    const [statesCep, setStatesCep] = useState<TStateResponse[] | undefined>([])
    const [cities, setCities] = useState<TCity[] | undefined>([])
    const formik = useFormik({
        initialValues: defaultCreditCardData,
        validationSchema: creditCardSchema,
        validateOnChange: false,
        isInitialValid: true,
        onSubmit: async () => {
            try {
                const requestData = buildCreditCardRequest(formData, {
                    saleId: saleId,
                    installments: billing.numberOfInstallments,
                    amount: billing.amount,
                    billingId: billing.id
                })

                await generatePayment(requestData)
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sucesso ao gerar Pagamento!!',
                    timer: 3000,
                })
                setTimeout(function () {
                    window.location.reload();
                }, 2000);

            } catch (error: any) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: error.response.data.message ?? 'Erro ao gerar pagamento.',
                    timer: 3000,
                })
            }
        },
    })

    function handleSubmit(event) {
        event.preventDefault();
        formik.setFieldValue('zipCode', formData.zipCode.replace(/\D/g, ''))
        formik.handleSubmit()
    }

    useEffect(() => {
        async function execute() {
            const data = await getStatesAndCities()
            let dataSorted = data?.sort((a, b) => a.name.localeCompare(b.name))
            setStatesCep(dataSorted)
        }
        execute()
    }, [])

    useEffect(() => {
        const cleanedValue = formData.zipCode.replace(/\D/g, '')
        if (cleanedValue?.length === 8) {
            fetchCep(cleanedValue)
        }
    }, [formData.zipCode])

    async function fetchCep(document: string) {
        try {
            const { data} = await getCepByViaCep(document)
            const findState = statesCep?.find((state) => state.uf === data.state)
            let stateFiltered = statesCep?.find((state) => state.id === findState?.id)
            const findCity = stateFiltered?.cities?.find((city) => city.name === data.city)
            setFormData({
                ...formData,
                street: data.address,
                neighborhood: data.neighbourhood,
                state: findState?.name,
                city: findCity?.name,
                stateCode: findState?.uf,
                cityId: String(findCity?.id),
            })
            formik.setFieldValue('street', data.address)
            formik.setFieldValue('neighborhood',data.neighbourhood)
            formik.setFieldValue('state', findState?.name)
            formik.setFieldValue('city', findCity?.name)
        } catch (error: any) { }
    }

    const handleInputCardChange = (evt) => {
        const { name, value } = evt.target
        
        if (name == 'cardNumber'){
            const valCardFormat = value.replace(/\D/g, '');
            setFormData({ ...formData, [name]: valCardFormat.slice(0, 16) })
            formik.setFieldValue(name, valCardFormat)
            return
        }

        setFormData({ ...formData, [name]: value })
        formik.setFieldValue(name, value)

    }

    const handlePaste = (evt) => {
        evt.preventDefault();
        const { name } = evt.target

        const pastedText = evt.clipboardData.getData('Text');
    
        const formatedValue = pastedText.replace(/\D/g, '');
    
        setFormData({ ...formData, [name]: formatedValue.slice(0, 16) })
        formik.setFieldValue(name, formatedValue.slice(0, 16))
      };

    const handleStateChange = (evt) => {
        const { value } = evt.target
        let stateFiltered = statesCep?.find((state) => state.id === parseInt(value))
        setCities(stateFiltered?.cities)
        setFormData({ ...formData, state: stateFiltered?.name, stateCode: stateFiltered?.uf })
        formik.setFieldValue('state', stateFiltered?.name)
    }

    const handleCityChange = (evt) => {
        const { value } = evt.target
        let cityFiltered = cities?.find((city) => city.id === parseInt(value))
        setFormData({ ...formData, city: cityFiltered?.name, cityId: String(cityFiltered?.id) })
        formik.setFieldValue('city', cityFiltered?.name)
    }

    return (
        <>
            <div className='accordion-item m-7'>
                <h2 className='accordion-header' id={`kt_accordion_1_header_3${billing.id}`}>
                    <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#kt_accordion_1_body_3${billing.id}`}
                        aria-expanded='false'
                        aria-controls={`kt_accordion_1_body_3${billing.id}`}
                    >
                        Pagar Com Crédito {'->'} Aprovação em 1 ou 2 dias úteis
                    </button>
                </h2>
                <div
                    id={`kt_accordion_1_body_3${billing.id}`}
                    className='accordion-collapse collapse'
                    aria-labelledby='kt_accordion_1_header_3'
                    data-bs-parent='#kt_accordion_1'
                >
                    <div className='accordion-body'>
                        <form
                            id='data-kt-users-modal-action'
                            className='form'
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className='container pt-10'>
                                <div className='row'>
                                    <div className='col-lg-6 text-start'>
                                        <h3 style={{ color: '#D31145' }} className='mb-4 text-start'>
                                            Dados do cartão{' '}
                                        </h3>
                                        <Cards
                                            number={formData.cardNumber}
                                            expiry={formData.expiry}
                                            cvc={formData.cvv}
                                            name={formData.holder}
                                        />
                                        <div className='position-relative mb-5'>
                                            <label className='d-flex align-items-center fs-6 mt-4 fw-bold form-label mb-2'>
                                                <span className='required'>Número do Cartão</span>
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title="Número do cartão"
                                                ></i>
                                            </label>
                                            <input
                                                type='text'
                                                name='cardNumber'
                                                maxLength={16}
                                                value={formData.cardNumber}
                                                className='form-control form-control-solid'
                                                onChange={handleInputCardChange}
                                                onPaste={handlePaste}
                                            />
                                            {<small className='text-danger'>{formik.errors.cardNumber}</small>}
                                        </div>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Nome impresso no cartão</span>
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title="Nome impresso no cartão"
                                                ></i>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control form-control-solid'
                                                name='holder'
                                                placeholder='Nome impresso no cartão'
                                                value={formData.holder}
                                                onChange={handleInputCardChange}
                                            />
                                            {<small className='text-danger'>{formik.errors.holder}</small>}
                                        </div>
                                        <div className='mb-4'>
                                            <div className='row'>
                                                <div className='col-lg-8'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Válido até</span>
                                                        <i
                                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                                            data-bs-toggle='tooltip'
                                                            title="Validade"
                                                        ></i>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        maxLength={5}
                                                        name='expiry'
                                                        className='form-control form-control-solid'
                                                        placeholder='Val'
                                                        value={formatDateCrediCard(formData.expiry)}
                                                        onChange={handleInputCardChange}
                                                    />
                                                    {<small className='text-danger'>{formik.errors.expiry}</small>}
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>CVV</span>
                                                        <i
                                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                                            data-bs-toggle='tooltip'
                                                            title="Valor de Verificação do Cartão"
                                                        ></i>
                                                    </label>
                                                    <input
                                                        type='number'
                                                        name='cvv'
                                                        placeholder='***'
                                                        className='form-control form-control-solid'
                                                        onChange={handleInputCardChange}
                                                    />
                                                    {<small className='text-danger'>{formik.errors.cvv}</small>}
                                                </div>

                                            </div>
                                        </div>
                                        <div className='mb-4 text-start'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span>Número de parcelas</span>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        readOnly
                                                        className='form-control form-control-solid'
                                                        name='installments'
                                                        value={`${billing.numberOfInstallments} de ${formatMask(billing?.installments[0]?.amount)}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 text-center'>
                                        <h3 style={{ color: '#D31145' }} className='mb-4'>Endereço da cobrança </h3>
                                        <div className='mb-8 text-start'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>CEP</span>
                                            </label>
                                            <input
                                                type='text'
                                                name='zipCode'
                                                className='form-control form-control-solid'
                                                value={formData.zipCode}
                                                onChange={handleInputCardChange}
                                            />
                                            {<small className='text-danger'>{formik.errors.zipCode}</small>}
                                        </div>
                                        <div className='row col-12 mb-8 text-start'>
                                            <div className='col-6'>
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span className='required'>Estado</span>
                                                </label>
                                                <select
                                                    className='form-select form-select-lg form-select-solid'
                                                    onChange={handleStateChange}
                                                >
                                                    <option
                                                        value={formData.state ? formData.state : -1}
                                                    >
                                                        {formData.state ? formData.state : 'Selecione um estado'}
                                                    </option>
                                                    {statesCep?.map((state) => (
                                                        <option key={state.id} value={state.id}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {<small className='text-danger'>{formik.errors.state}</small>}
                                            </div>
                                            <div className='col-6'>
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span className='required'>Cidade</span>
                                                </label>
                                                <select
                                                    className='form-select form-select-lg form-select-solid'
                                                    onChange={handleCityChange}
                                                >
                                                    <option value={formData.city ? formData.city : -1}>
                                                        {formData.city ? formData.city : 'Selecione uma cidade'}
                                                    </option>
                                                    {cities?.map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <small className='text-danger'>{formik.errors.city}</small>
                                            </div>
                                        </div>
                                        <div className='mb-5 text-start'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Bairro</span>
                                            </label>
                                            <input
                                                type='text'
                                                name='neighborhood'
                                                className='form-control form-control-solid'
                                                value={formData.neighborhood}
                                                onChange={handleInputCardChange}
                                            />
                                            {<small className='text-danger'>{formik.errors.neighborhood}</small>}
                                        </div>
                                        <div className='mb-7 text-start'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Rua/Avenida</span>
                                            </label>
                                            <input
                                                type='text'
                                                name='street'
                                                className='form-control form-control-solid mb-3 mb-lg-0'
                                                value={formData.street}
                                                onChange={handleInputCardChange}
                                            />
                                            {<small className='text-danger'>{formik.errors.street}</small>}
                                        </div>
                                        <div className='row col-12 mb-4 text-start'>
                                            <div className='col-5 mb-8'>
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span className='required'>Número</span>
                                                </label>
                                                <input
                                                    type='text'
                                                    name='number'
                                                    className='form-control form-control-solid'
                                                    value={formData.number}
                                                    maxLength={3}
                                                    onChange={handleInputCardChange}
                                                />
                                                {<small className='text-danger'>{formik.errors.number}</small>}
                                            </div>
                                            <div className='col-7 mb-8'>
                                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                    <span>Complemento</span>
                                                </label>
                                                <input
                                                    type='text'
                                                    name='complement'
                                                    className='form-control form-control-solid'
                                                    value={formData.complement}
                                                    onChange={handleInputCardChange}
                                                />
                                                {<small className='text-danger'>{formik.errors.complement}</small>}
                                            </div>
                                        </div>
                                        <div className='mb-4 text-start'>
                                            <button
                                                type='submit'
                                                style={{ borderRadius: 36 }}
                                                className='btn btn-success col-lg-12'
                                                data-kt-users-modal-action='submit'
                                                disabled={formik.isSubmitting}
                                            >
                                                <span className='indicator-label'>Confirmar Pagamento</span>
                                                {(formik.isSubmitting) && (
                                                    <span className='indicator-progress'>
                                                        Aguarde...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export { CreditCardAccordion }
