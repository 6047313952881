import axios, { AxiosResponse } from 'axios'
import { TProductRequest } from '../../../coreGlobal/models/products/product'
import { ID, Response } from '../../../_metronic/helpers'
import { ISelect } from '../../../coreGlobal/models/default'
import { ProductsQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const CATEGORY_URL = `${API_URL}/products/categories`
const getCategories = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${CATEGORY_URL}/all`)
    .then((response: AxiosResponse<Response<ISelect[]>>) => response.data)
    .then((response: Response<ISelect[]>) => response.data)
}

const CONTRACTTERM_URL = `${API_URL}/products/contractTerm/all`
const getTerms = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${CONTRACTTERM_URL}`)
    .then((response: AxiosResponse<Response<ISelect[]>>) => response.data)
    .then((response: Response<ISelect[]>) => response.data)
}

const PRODUCT_URL = `${API_URL}/products`
const getProductById = (productId: string | number) => {
  return axios
    .get(`${PRODUCT_URL}/${productId}?include=contractTerm,category`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateProduct = (productsId: string | number, data: TProductRequest) => {
  return axios
    .put(`${PRODUCT_URL}/${productsId}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const createProduct = (data: TProductRequest) => {
  return axios
    .post(`${PRODUCT_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getProducts = (query: string): Promise<ProductsQueryResponse> => {
  return axios
      .get(`${PRODUCT_URL}?${query}`)
      .then((d: AxiosResponse<ProductsQueryResponse>) => d.data)
}

const deleteProduct = (productId: ID) => {
  return axios
      .delete(`${PRODUCT_URL}/${productId}`)
      .then((d) => d.data)
}

export { getCategories, getTerms, getProductById, updateProduct, createProduct, getProducts, deleteProduct };

