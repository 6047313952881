import { User } from "../../../pages/users/list/core/_models"
import { TRoom } from "../default"
import { IPersonResponse } from "../person"
import { IProductResponse } from "../products/product"
import { SaleItemResponse } from "../sale"
import { IUserResponse } from "../user"

export type ScheduleResponse = {
    room:  number
    evaluation: boolean
    products: Array<number>
    machine?: number
    dateInit: string
    client: number
    session: string
    fitting: boolean
}

export type ScheduleValidate = {
    room:  number
    products: Array<number>
    evaluation: boolean
    client: number
    product_id?: Array<number>
    machine?: number
    dateInit: string
    dateHour?: string
    fitting: boolean
    session: string
}

export type ScheduleHighlightRequest = {
    highlight?: boolean
    observation?: string
}

export const ScheduleInitValues: ScheduleValidate = {
    room: -1,
    evaluation: false,
    products: [],
    product_id: [],
    client: -1,
    dateInit: '',
    dateHour: '',
    machine: undefined,
    fitting: false,
    session: ''
}

export type ScheduleAppointmentsResponse = {
    id: string,
    status_id: string,
    dateInit: string,
    dateEnd: string,
    highlight: boolean,
    observation: string,
    createdAt: string,
    updatedAt: string,
    items: TSchedulesItems[]
    client?: IPersonResponse,
    room?: TRoom,
    user?: IUserResponse,
    lastHistory?: TLastHistory
  }
  
  export type TSchedulesItems = {
    id: string,
    sessionNumber: string,
    createdAt: string,
    updatedAt: string,
    saleItem: SaleItemResponse,
    medicalRecord?: TMedicalRecord
    evaluationProduct?: IProductResponse
    schedule?: ScheduleAppointmentsResponse
    lastHistory?: TLastHistory
  }
  type TLastHistory = {
    id: string,
    status_id: string,
    observation: string,
    createdAt: string,
    updatedAt: string,
    status: Tstatus,
    user: User
  }
  
  type Tstatus = {
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string
  }
  type TMedicalRecord = {
    id: string,
    status: string,
    machineFrequency: string,
    observation: string,
    createdAt: string,
    updatedAt: string,
    item?: TSchedulesItems[],
    user?: IUserResponse,
    attendant: IUserResponse,
  }