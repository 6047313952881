/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ClientTemporarily} from '../../core/_models'
import {phoneMask} from '../../../../../utils/phoneMask'

type Props = {
  people: ClientTemporarily
}

const DescriptionCell: FC<Props> = ({people}) => (
  <>
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{people?.description}</span>
      </div>
    </div>
  </>
)

export {DescriptionCell}
