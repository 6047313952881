import * as Yup from 'yup'
import {AddressValidSchema} from '../../../../coreGlobal/validation/AddressVali'
import {ContactValidSchema} from '../../../../coreGlobal/validation/ContactVali'
import {BankDataValidSchema} from '../../../../coreGlobal/validation/BankDataVali'

export const franchiseSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome da Franquia'),
  email: Yup.string().email('Digite um email válido').required('Insira o e-mail da Franquia'),
  document: Yup.string().required('Informe um documento').min(14, 'Minimo 14 caracteres'),
  person: Yup.object().shape({
    name: Yup.string().required('Insira a razão social'),
    juridicalPerson: Yup.object().shape({
      fantasyName: Yup.string().required('Insira o nome fantasia'),
    }),
  }),
  addresses: Yup.array().of(AddressValidSchema).required().min(1),
  contacts: Yup.array().of(ContactValidSchema).required().min(1),
  bankData: Yup.array().of(BankDataValidSchema),
})

export const franchiseConfigSchema = Yup.object().shape({
  startTime: Yup.string().when('type', {
    is: (val) => val === 2,
    then: () =>
      Yup.string()
        .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Formato de horário de início inválido')
        .required('O horário de início é obrigatório')
        .test(
          'is-after-end',
          'O horário de início não pode ser menor ou igual que o horário de término',
          function (startTime) {
            const {endTime} = this.parent
            if (!startTime || !endTime) {
              return true
            }
            const startTimeObj = startTime
            const endTimeObj = endTime
            return startTimeObj < endTimeObj
          }
        ),
  }),
  endTime: Yup.string().when('type', {
    is: (val) => val === 2,
    then: () =>
      Yup.string()
        .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Formato de horário de término inválido')
        .required('O horário de témino é obrigatório')
        .test(
          'is-after',
          'O horário de término deve ser maior que o horário de início',
          function (endTime) {
            const {startTime} = this.parent
            if (!startTime || !endTime) {
              return true
            }
            const startTimeObj = startTime
            const endTimeObj = endTime
            return startTimeObj < endTimeObj
          }
        ),
  }),
})