import axios from "axios"
import { TCreateUserRequest, TEditUserRequest } from "../../../coreGlobal/models/user"

const API_URL = process.env.REACT_APP_API_URL + '/api'
const USER_URL = `${API_URL}/users`
const GROUP_URL = `${API_URL}/groups/all`
const FRANCHISE_URL = `${API_URL}/franchises/all`
const VALIDATION_URL = `${API_URL}`

const getUsersById = (userId: string | number) => {
  return axios
    .get(`${USER_URL}/${userId}?include=person.physicalPerson,roles,franchises`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getGroups = (): Promise<any | undefined> => {
  return axios
    .get(`${GROUP_URL}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getFranchises = (): Promise<any | undefined> => {
  return axios
    .get(`${FRANCHISE_URL}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const createUser = (request: TCreateUserRequest) => {
  return axios
    .post(`${API_URL}/users`, request)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updateUser = (userId: string | number, data: TEditUserRequest) => {
  return axios
    .put(`${API_URL}/users/${userId}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const validatePersonPhone = (number: string) => {
  return axios
    .get(`${VALIDATION_URL}/validation/phone/${number}`,)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export { getUsersById, getGroups, getFranchises, createUser, updateUser }