import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const GROUP_URL = `${API_URL}/groups`

const getGroupIdById = (groupID: string | number) => {
    return axios
      .get(`${GROUP_URL}/${groupID}`)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
}


export { getGroupIdById,};