/* eslint-disable react-hooks/exhaustive-deps */
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Cart} from './components/Cart'
import {CartProvider, useCart} from './core/cartProvider'
import {ModalContracts} from './components/ModalContracts'
import {Client} from './components/Client'
import {ClientCardProvider, useClientCart} from './core/clientProvider'
import {ResponsibleCardProvider, useResponsibleCart} from './core/responsibleProvider'
import {SalePaymentConfig} from '../../../components/SalePaymentConfig'
import {useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import {getBudgetById} from '../../budgets/core/_request'
import {PeopleProvider, usePeople} from '../../../coreGlobal/providers/PersonProvider'
import {PersonCreateModal} from '../../../components/Person/PersonCreateModal'
import {TPersonRequest} from '../../../coreGlobal/models/person'
import {mapBudgetToShopping} from './utils/mapBudgetToShopping'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'

const SalesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Vendas',
    path: '/sales',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const initialValues: TPersonRequest = {
  type: 1,
  name: '',
  preferredName: '',
  email: '',
  document: '',
  birthDate: '',
  gender: '',
  rg: '',
  job: '',
  nationality: 22,
  addresses: [],
  bankData: [],
  medicalConditions: [],
  reduceTime: true,
  hideAvatar: false,
}

const Sales = () => {
  const {showContracts, fetchClient, removePerson, loading} = useClientCart()
  const {
    showPaymentModal,
    setShoppingCart,
    setBudgetData,
    setBudgetForSale,
    setEnableBudget,
    refreshToNewSale,
    changeToken,
  } = useCart()
  const {fetchResponsible, removePerson: removeResponsible} = useResponsibleCart()
  const {showCreatePersonModal} = usePeople()

  const [searchParams] = useSearchParams()

  const budgetId = searchParams.get('budget') ?? null

  const clean = () => {
    refreshToNewSale()
    removePerson()
    removeResponsible()
    setBudgetData(undefined)
    setBudgetForSale(null)
    setEnableBudget(true)
  }

  useEffect(() => {
    if (budgetId == null) {
      clean()
      return
    }

    const init = async () => {
      if (budgetId) {
        const data = await getBudgetById(budgetId)

        fetchClient(data.client)
        if (data.responsible != null) fetchResponsible(data.responsible)
        setShoppingCart(mapBudgetToShopping(data))
        setBudgetForSale(data.id)
        setBudgetData(data)
        setEnableBudget(false)

        const hasSession = data?.session || false
        if (hasSession) {
          changeToken(data.session, 'APPROVE')
        }
      }
    }
    try {
      init()
    } catch (error) {
      clean()
    }
  }, [budgetId])

  return (
    <>
      <div className='row'>
        <div className='col-md-12 col-12 h-100'>
          <div className='card'>
            <div className='card-header py-4 border-bottom border-4'>
              <Client />
            </div>
            <div className='card-body pt-0'>
              <Cart />
            </div>
          </div>
        </div>
      </div>
      {showContracts && <ModalContracts />}
      {showPaymentModal && <SalePaymentConfig />}
      {showCreatePersonModal && (
        <PersonCreateModal
          action={(values) => fetchClient(values)}
          person={initialValues}
          index={0}
        />
      )}
    </>
  )
}

export const SalesCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={SalesBreadcrumbs}>Realizar venda</PageTitle>
      <CartProvider>
        <ClientCardProvider>
          <ResponsibleCardProvider>
            <PeopleProvider>
              <Sales />
            </PeopleProvider>
          </ResponsibleCardProvider>
        </ClientCardProvider>
      </CartProvider>
    </>
  )
}
