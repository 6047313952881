import { FC, useEffect, useMemo, useState } from "react"
import { KTIcon } from "../../_metronic/helpers"
import { phoneMask } from "../../utils/phoneMask"
import { FieldArray, FormikProvider } from "formik";
import { useDebounceFunction } from "../../hooks/useDebounceFunction";
import Swal from "sweetalert2";
import { phoneRegex } from "../../utils/regexs/phone";
import { validatePersonPhone } from "../../coreGlobal/_resquest";
import { cleanMask } from "../../utils/cleanMask";

type Props = {
  formik: any,
  complete: string
}

const DEFAULT_PHONE = { type: 1, phone: '' }

interface IPhonesAlreadyValidate {
  phone: string
  isValid: boolean
}

interface IPhoneField {
  formik: any,
  index: number,
  phonesAlreadyValidate: IPhonesAlreadyValidate[]
  setPhonesAlreadyValidate: React.Dispatch<React.SetStateAction<IPhonesAlreadyValidate[]>>
}

const PhoneField = ({ formik, index, phonesAlreadyValidate, setPhonesAlreadyValidate }: IPhoneField) => {

  const { phone, type } = formik.values.contacts[index];

  const validateUserPhone = useDebounceFunction(async ({ phone, phonesAlreadyValidate }) => {

    if (!phoneRegex.test(phone)) return;
    if (phonesAlreadyValidate.some((p) => p.phone == phone)) return;

    Swal.fire({
      position: 'center',
      icon: 'info',
      title: `Validando Telefone: ${phone}`,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      await validatePersonPhone(cleanMask(phone));
      setPhonesAlreadyValidate([...phonesAlreadyValidate, {
        phone: phone,
        isValid: true
      }])
      await Swal.fire({
        icon: 'success',
        title: 'Validação concluída',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000
      });

    } catch (error: any) {
      formik.setFieldValue(`contacts[${index}].phone`, "")

      if (error.response?.status === 422) {
        let errorText = '';
        for (let key in error.response.data.errors) {
          errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
        }
        await Swal.fire({
          icon: 'error',
          title: 'Ops! Revise seu formulário e tente novamente!',
          html: errorText
        });
        return;
      }

      if (error.response?.status === 400) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Telefone inválido",
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 2000
        })
        return;
      }

      if (error.response?.status === 500) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Tente novamente mais tarde",
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 2000
        })
        return;
      }

      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message,
        showConfirmButton: false,
        allowOutsideClick: false,
      })
    } finally {
      Swal.close();
    }
  }, 1000);

  useEffect(() => {
    if (type != "1") return;
    validateUserPhone({ phone, phonesAlreadyValidate })
  }, [phone, type])

  return (
    <td className="col-lg-3">
      <input
        id={`phone${index}`}
        type='text'
        onBlur={formik.handleBlur}
        value={phone}
        className='form-control form-control-lg form-control-solid'
        placeholder='Número'
        onChange={(e) => {
          formik.setFieldValue(`contacts[${index}].phone`, phoneMask(e.target.value))
        }}
        maxLength={15}
      />
      {formik.touched[`phone${index}`] && formik.errors?.contacts && formik.errors?.contacts[index]?.phone ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            {formik.errors?.contacts[index]?.phone}
          </div>
        </div>
      ) : null}
    </td>
  );
}


const ContactForm: FC<Props> = ({ formik, complete }) => {

  const cloneDeepPhonesAlreadyValidate = useMemo(() => {
    return formik.values.contacts.filter((t) => t.phone != "");
  }, []);

  const [phonesAlreadyValidate, setPhonesAlreadyValidate] = useState<IPhonesAlreadyValidate[]>(cloneDeepPhonesAlreadyValidate);

  return (
    <div className="card mb-5 mb-xl-10 shadow-sm">
      <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_contact_card_collapsible">
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados de Contato:</h3>
        </div>
        <div className="card-toolbar rotate-180">
          <i className="ki-duotone ki-down fs-1"></i>
        </div>
      </div>
      <div id="kt_contact_card_collapsible" className="collapse show">
        <div className="card-body">
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>
              <span className={complete}>E-mail</span>
            </label>
            <div className='col-lg-10 fv-row'>
              <input
                id="email"
                type='text'
                onBlur={formik.handleBlur}
                className='form-control form-control-lg form-control-solid'
                placeholder='E-mail'
                {...formik.getFieldProps('email')}
              />
              {formik.touched?.email && formik.errors?.email ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors?.email}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <FormikProvider value={formik}>
            <FieldArray
              name="contacts"
              render={(contacts) => (
                <>
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                    <tbody>
                      {
                        formik.values?.contacts?.map((field, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle col-lg-2">
                                <label className='col-lg-12 col-form-label required fw-bold fs-6'>Telefone: {index + 1}</label>
                              </td>
                              <td className="col-lg-3">
                                <select
                                  id={`typeId${index}`}
                                  value={formik.values.contacts[index].type}
                                  className='form-select form-select-lg form-select-solid'
                                  onChange={(e) => {
                                    formik.setFieldValue(`contacts[${index}].type`, e.target.value)
                                  }}
                                >
                                  <option value="">Selecione o tipo de contato</option>
                                  <option value="1">Celular</option>
                                  {index > 0 ? <option value="2">Comercial</option> : ''}
                                  {index > 0 ? <option value="3">Residencial</option> : ''}

                                </select>
                                {formik.touched?.contacts && formik.touched?.contacts[index] && formik.errors?.contacts && formik.errors?.contacts[index]?.type ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors?.contacts[index]?.type}
                                    </div>
                                  </div>
                                ) : null}
                              </td>
                              <PhoneField formik={formik} index={index} phonesAlreadyValidate={phonesAlreadyValidate} setPhonesAlreadyValidate={setPhonesAlreadyValidate} />
                              <td className="align-middle">
                                {index > 0 &&
                                  <button
                                    id={`${index}`}
                                    type="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      contacts.remove(index)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                  >
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </button>
                                }
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <button onClick={(e) => {
                    e.preventDefault();
                    contacts.push(DEFAULT_PHONE)
                  }} className="btn btn-primary">
                    Adicionar Telefone
                  </button>
                </>
              )
              } />
          </FormikProvider>
        </div>
      </div>
    </div>
  )
}
export default ContactForm