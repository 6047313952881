/* eslint-disable no-unused-expressions */
import React, {cloneElement, useEffect, useMemo, useState} from 'react'

import PropTypes from 'prop-types'
import {Navigate, EventProps} from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import {getRooms} from '../../../coreGlobal/_resquest'
import {parseToResource} from '../utils/parseToResource'
import moment from 'moment'
import {useCalendar} from '../../../hooks/useCalendar'
import {convertTimeToHours} from '../../../coreGlobal/Helpers/utils'
import {useAuth} from '../../../app/modules/auth'
import {useSearchParams} from 'react-router-dom'
const startHour = moment()
const endHour = moment()

export default function CustomDayView({
  date,
  localizer,
  max,
  min,
  scrollToTime = localizer.startOf(new Date(), 'day'),
  ...props
}) {
  const {currentFranchise} = useAuth()

  const [horasStart, minutosStart] = String(currentFranchise?.startTime).split(':')
  const [horasEnd, minutosEnd] = String(currentFranchise?.endTime).split(':')

  startHour.set({
    hour: parseInt(horasStart, 10),
    minute: parseInt(minutosStart, 10),
    second: 0,
    millisecond: 0,
  })

  endHour.set({
    hour: parseInt(horasEnd, 10),
    minute: parseInt(minutosEnd, 10),
    second: 0,
    millisecond: 0,
  })

  min = localizer.startOf(startHour)
  max = localizer.endOf(endHour)

  const currRange = useMemo(() => CustomDayView.range(date, {localizer}), [date, localizer])

  const {rooms, selectedFilterRoom} = useCalendar()

  let [searchParams] = useSearchParams()
  const roomSelected = searchParams.get('room') || 1
  const selectedOption = rooms?.find((room) => room.id == roomSelected)

  const resources =
    selectedOption == undefined
      ? rooms
      : rooms.filter((room) => {
          return room.id == selectedOption.id
        })

  const steps = 5
  const TIME_SLOTS = 3

  return (
    <>
      <TimeGrid
        date={date}
        eventOffset={15}
        localizer={localizer}
        range={currRange}
        resources={resources}
        scrollToTime={scrollToTime}
        {...props}
        max={max}
        min={min}
        step={steps}
        timeslots={TIME_SLOTS}
      />
    </>
  )
}

CustomDayView.range = (date, {localizer}) => {
  const start = date
  const end = date

  let current = start
  const range = [] as any

  while (localizer.lte(current, end, 'day')) {
    range.push(current)
    current = localizer.add(current, 1, 'day')
  }

  return range
}

CustomDayView.navigate = (date, action, {localizer}) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'day')

    case Navigate.NEXT:
      return localizer.add(date, 1, 'day')

    default:
      return date
  }
}

CustomDayView.title = (date, {localizer}) => {
  return moment(date).format('DD [de] MMMM').toUpperCase()
}
