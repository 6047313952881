import {useEffect, useState} from 'react'
import {SalesModal} from './SalesModal'
import {Sale} from './Sale/sale'

export const SalesForm = ({people}) => {
  const [showSalesModal, setShowSalesModal] = useState<boolean>(false)
  const [datePositionModal, setDatePositionModal] = useState()
  const [sales, setSales] = useState()
  useEffect(() => {
    setSales(people?.sales)
  }, [])

  return (
    <>
      <div className='table-responsive'>
        <table className='table table-bordered table-row-gray-300 gy-7'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th>Número Contrato Antigo</th>
              <th>Número Contrato</th>
              <th>Data</th>
              <th>Desconto</th>
              <th>Valor Total</th>
              <th>Situação</th>
              <th>Distrato</th>
              <th>Valor Distrato</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <Sale
              sales={sales}
              setShowSalesModal={setShowSalesModal}
              setDatePositionModal={setDatePositionModal}
            />
          </tbody>
        </table>
      </div>
      {showSalesModal && (
        <SalesModal
          data={datePositionModal}
          people={people}
          close={() => setShowSalesModal(false)}
        />
      )}
    </>
  )
}
