/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ClientTemporarily} from '../../core/_models'

type Props = {
  people: ClientTemporarily
}

const DocCell: FC<Props> = ({people}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{people?.document}</span>
    </div>
  </div>
)

export {DocCell}
