import {createContext, FC, useContext, useState} from 'react'
import {ISelect} from '../../../coreGlobal/models/default'
import {ContactRequest} from '../../../coreGlobal/models/person'
import {WithChildren} from '../../../_metronic/helpers'
import {getCountries} from '../../../coreGlobal/_resquest'
import {TClientTemporarilyResponse} from '../update/models/_models'
import {getClientTemporarilyById} from '../update/models/_request'

interface IClientTemporarilyUpdateContext {
  countries: ISelect[]
  setCountries: React.Dispatch<React.SetStateAction<ISelect[]>>
  getDataCountries: () => void

  country: ISelect | undefined
  setCountry: React.Dispatch<React.SetStateAction<ISelect | undefined>>

  openClientModal: boolean
  setOpenClientModal: React.Dispatch<React.SetStateAction<boolean>>

  contacts: ContactRequest[]
  setContacts: React.Dispatch<React.SetStateAction<ContactRequest[]>>

  persons: TClientTemporarilyResponse[]
  setPersons: React.Dispatch<React.SetStateAction<any>>

  dataValidateDoc: any[]
  setDataValidateDoc: React.Dispatch<React.SetStateAction<any>>

  getDataPersons: (id: string) => void

  reduceTimeRadio: boolean
  setReduceTimeRadio: React.Dispatch<React.SetStateAction<boolean>>

  hideAvatarRadio: boolean
  setHideAvatarRadio: React.Dispatch<React.SetStateAction<boolean>>
}

const ClientTemporarilyUpdateContext = createContext<IClientTemporarilyUpdateContext>(
  {} as IClientTemporarilyUpdateContext
)

const ClientTemporarilyUpdateProvider: FC<WithChildren> = ({children}) => {
  const [countries, setCountries] = useState<ISelect[]>([{value: '', label: ''}])
  const [country, setCountry] = useState<ISelect | undefined>()
  const [openClientModal, setOpenClientModal] = useState(false)
  const [contacts, setContacts] = useState<ContactRequest[]>([{id: 0, type: 1, phone: ''}])
  const [dataValidateDoc, setDataValidateDoc] = useState<any>()

  const [reduceTimeRadio, setReduceTimeRadio] = useState<boolean>(true)
  const [hideAvatarRadio, setHideAvatarRadio] = useState<boolean>(false)

  const getDataCountries = async () => {
    const data = await getCountries()
    if (data !== undefined) {
      setCountries(data)
    } else {
      setCountries([{value: '', label: ''}])
    }
  }

  const [persons, setPersons] = useState<TClientTemporarilyResponse[]>([])

  const getDataPersons = async (id: string) => {
    const data = await getClientTemporarilyById(id)
    setPersons([data])
    return [data]
  }

  return (
    <ClientTemporarilyUpdateContext.Provider
      value={{
        countries,
        setCountries,
        getDataCountries,

        country,
        setCountry,

        openClientModal,
        setOpenClientModal,

        contacts,
        setContacts,

        persons,
        setPersons,
        getDataPersons,

        dataValidateDoc,
        setDataValidateDoc,

        reduceTimeRadio,
        setReduceTimeRadio,
        hideAvatarRadio,
        setHideAvatarRadio,
      }}
    >
      {children}
    </ClientTemporarilyUpdateContext.Provider>
  )
}

const useClientTemporarilyUpdate = () => useContext(ClientTemporarilyUpdateContext)
export {ClientTemporarilyUpdateProvider, useClientTemporarilyUpdate}
