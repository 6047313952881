/* eslint-disable react-hooks/exhaustive-deps */
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { useState } from 'react'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import PeopleForm from '../../components/Person/PeopleForm'
import { TPersonRequest } from '../../coreGlobal/models/person'
import { PersonValidSchema } from '../../coreGlobal/validation/PersonVali'
import { buildPersonRequest } from '../../coreGlobal/buildPersonRequest'
import { createPerson } from '../../coreGlobal/_resquest'
import { PeopleProvider } from '../../coreGlobal/providers/PersonProvider'
import { useNavigate } from 'react-router-dom'

const peopleBreadcrumbs: Array<PageLink> = [
  {
    title: 'Cliente',
    path: '/people',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const Create = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues: TPersonRequest = {
    type: 1,
    name: '',
    preferredName: '',
    email: '',
    document: '',
    birthDate: '',
    gender: '',
    rg: '',
    job: '',
    nationality: 22,
    reduceTime: true,
    hideAvatar: false,
    contacts: [
      {
        type: 0,
        phone: ""
      }
    ],
    medicalConditions:[],
    observations: []
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(),
    onSubmit: async (values) => {   
      const valuesFilter = buildPersonRequest(values)
      await submit(valuesFilter)
    },
  });

  const submit = async (req: FormData) => {
    await Swal.fire({
      title: 'Deseja criar o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createPerson(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/people/list', { replace: true });
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <PeopleProvider>
        <PageTitle breadcrumbs={peopleBreadcrumbs}>Criar Cliente</PageTitle>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <PeopleForm formik={formik} type={1} complete={''} mode='CREATE' />

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
            <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </PeopleProvider>
    </>
  )
}

export const PeopleCreatePage = () => {
  return (
      <>
          <PageTitle breadcrumbs={peopleBreadcrumbs}>Adicionar Categoria</PageTitle>
          <Create />
      </>
  )
}