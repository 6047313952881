import { KTIcon } from '../../../../_metronic/helpers'
import { TooltipWrapper } from '../../../../components/Tooltip'
import { formatDateTime } from '../../../../utils/dayJs/format'
import { formatMonetaryToBr } from '../../../../utils/formatMonetaryToBr'

const CustomTd = ({ children }) => {
  return <td style={{ paddingTop: 3, paddingBottom: 3 }}>{children}</td>
}

export function Sale({ sales, setShowSalesModal, setDatePositionModal }) {
  return sales?.map((sale, index) => {
    let discount = 0
    sale?.items?.map((items) => {
      discount += Number(items.discount)
    })
    return (
      <>
        <tr key={index} className='fw-bold' style={{ backgroundColor: 'rgb(220 219 219)' }}>
          <td className='p-8'>{sale.oldId}</td>
          <td>{sale.id}</td>
          <td>{formatDateTime(sale.createdAt)}</td>
          <td>{formatMonetaryToBr(discount)}</td>
          <td>{formatMonetaryToBr(sale.price)}</td>
          <td rowSpan={2} className='text-center min-w-100px' style={{ verticalAlign: 'middle' }}>
            {sale.status}
            {sale?.lastHistory?.status?.description && <TooltipWrapper
              tooltipText={sale?.lastHistory?.status?.description}
              tooltipPlacement={'top'}
            >
              <button className='btn btn-icon  btn-active-color-danger btn-sm' >
                <KTIcon iconName='information' className='fs-3' />
              </button>
            </TooltipWrapper>
            }
          </td>
          <td
            rowSpan={2}
            className='text-center min-w-100px'
            style={{ verticalAlign: 'middle' }}
          ></td>
          <td
            rowSpan={2}
            className='text-center min-w-100px'
            style={{ verticalAlign: 'middle' }}
          ></td>
          <td rowSpan={2} className='text-center min-w-100px' style={{ verticalAlign: 'middle' }}>
            <button
              onClick={() => {
                setShowSalesModal(true)
                setDatePositionModal(sale)
              }}
              className='btn btn-icon  btn-active-color-danger btn-sm'
            >
              <KTIcon iconName='gear' className='fs-3' />
            </button>
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <table className='table table-bordered' width='100%'>
              <thead>
                <tr>
                  <th className='fw-bold col-1' style={{ paddingLeft: '1rem' }}>
                    ID
                  </th>
                  <th className='fw-bold col-4'>Nome</th>
                  <th className='fw-bold col-1'>Sessão</th>
                  <th className='fw-bold col-1'>Realizadas</th>
                  <th className='fw-bold col-2'>Desconto</th>
                  <th className='fw-bold col-2'>Valor</th>
                  <th className='fw-bold col-2'>User</th>
                </tr>
              </thead>
              <tbody>
                {sale?.items?.map((items, index) => {
                  return (
                    <tr key={`itemsInternal${index}`} className='fw-bold'>
                      <CustomTd>{items.product?.id}</CustomTd>
                      <CustomTd>{items.product?.name}</CustomTd>
                      <CustomTd>{items.sessions}</CustomTd>
                      <CustomTd>{items.sessionsRealized}</CustomTd>
                      <CustomTd>{formatMonetaryToBr(items.discount)}</CustomTd>
                      <CustomTd>{formatMonetaryToBr(items.totalPrice)}</CustomTd>
                      <CustomTd>{items.seller?.name}</CustomTd>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </td>
        </tr>
      </>
    )
  })
}
