import dayjs from 'dayjs'
import {formatMonetaryToBr} from '../../../../utils/formatMonetaryToBr'

interface ISplitInfo {
  value: number
  date: Date
}

export const SplitInfoPix = ({value, date}: ISplitInfo) => {
  const dateFormat = dayjs(date).format('DD/MM/YYYY')
  return (
    <span className='fw-bold d-block'>
      PIX: {value} - {dateFormat}
    </span>
  )
}
