import { ErrorMessage } from "formik"
import { KTIcon } from "../../../_metronic/helpers"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";
import Swal from "sweetalert2";
interface ResetPassword {
    formik: any,
}

export const ResetPassWordForm = ({ formik }: ResetPassword) => {
    const [loading, setLoading] = useState(false);

    const { currentUser } = useAuth()
   
    return (
        <>
      
        <form onSubmit={formik.handleSubmit}>
            <div className='d-flex flex-column flex-root'>
                <div className='d-flex flex-column flex-left flex-column-fluid'>
                    <div className='d-flex flex-column flex-center text-left p-10'>
                        <div className='card card-flush  w-lg-1000px py-5'>
                            <div className='card-body py-2 lg-20' style={{ height: '750px' }}>
                                <div className='w-100'>
                                    <div className='pb-10 pb-lg-15'>
                                        <h1 className='fw-bolder d-flex align-items-center text-dark justify-content-center'>
                                            Atenção!
                                            <i
                                                className='fas fa-exclamation-circle ms-2 fs-7'
                                                data-bs-toggle='tooltip'
                                                title=''
                                            ></i>
                                        </h1>
                                    </div>
                                    <div className='fv-row '>
                                        <div className='row '>
                                            <p><h2> Por motivos de segurança, foi solicitado alteração de sua senha!</h2></p>
                                            <p>Digite a nova senha abaixo, e confirme logo em seguida!</p>
                                            <p>Após isto, você será redirecionado para acessar o sistema.</p>
                                            <p>ATENÇÃO: - Guarde esta nova senha, pois será a senha oficial!</p>
                                            <p className='text-danger'>Caso ocorra alguma divergência, informar à equipe de suporte do Sistema! </p>
                                            <p><b>Unidade:  </b></p>
                                            <p><b>Usuario: {currentUser?.name} </b></p>
                                            <p><b>E-mail:  {currentUser?.email} </b></p>
                                            <div className='row mb-5 mt-10'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span className='required'>Nova senha</span>
                                                </label>

                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='password'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Informe a nova senha'
                                                        {...formik.getFieldProps('password')}
                                                        maxLength={64}
                                                    />
                                                    {formik.touched.password && formik.errors.password && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors?.password}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                           
                                            <div className='row mb-10'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span className='required'>Confirmar Nova senha</span>
                                                </label>

                                                <div className='col-lg-8 fv-row'>
                                                    <input
                                                        type='password'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Confirmar a nova senha'
                                                        {...formik.getFieldProps('newPassWord')}
                                                        maxLength={64}
                                                    />
                                                    {formik.touched.newPassWord && formik.errors.newPassWord && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors?.newPassWord}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='row mb-5'>
                                                <div className='col-lg-4 fv-row'>
                                                </div>
                                                <div className='col-lg-8 fv-row'>
                                                    <p><b>A senha deve ter pelo menos 6 caracteres.</b></p>
                                                    <p><b>A senha deve conter pelo menos uma letra maiúscula.</b></p>
                                                    <p><b>A senha deve conter pelo menos uma letra minúscula.</b></p>
                                                    <p><b>A senha deve conter pelo menos um dígito(Ex: 1,2.3......9).</b></p>
                                                    <p><b>A senha deve conter pelo menos um caractere especial(Ex: !@#$%).</b></p>
                                                </div>
                                            </div>

                                            <button type='submit' className='btn btn-primary d-flex align-items-center justify-content-center' disabled={loading || !formik.isValid}>
                                                {!loading && 'Atualizar senha'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Salvando...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </>
    )
}