import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ListWrapper } from './ListWrapper'

const productBreadcrumbs: Array<PageLink> = [
  {
    title: 'Gestão de Clientes',
    path: '/people',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={productBreadcrumbs}>Lista de Clientes</PageTitle>
      <ListWrapper />
    </>
  )
}

export default ProductPage
