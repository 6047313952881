
import { useState } from "react";
import { LoadingSpinner } from "../../utils/loading/loadingSpinner";
import { ResetPassWordForm } from "./components/ResetPassWordForm";
import { useFormik } from "formik";
import { passWordSchema } from "./core/validation/PassWordVali";
import Swal from "sweetalert2";
import { changePassword } from "../../coreGlobal/_resquest";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../app/modules/auth";

interface PasswordResetRequest {
    password: string,
    newPassWord: string
}
const Update = () => {
    const navigate = useNavigate()
    const [loadingPage, setLoadingPage] = useState(false);
    const [loading, setLoading] = useState(false)
    const { setCurrentUser } = useAuth();
    const [passWordInicitalValues] = useState({
        password: '',
        newPassWord: ''
    })

    const formik = useFormik({
        initialValues: passWordInicitalValues,
        validationSchema: passWordSchema,
        onSubmit: async (values) => { handleSubmit(values) }
    })


    const handleSubmit = async (values: PasswordResetRequest) => {

        await Swal.fire({
            title: 'Deseja alterar a senha ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true)
                    const data = await changePassword(values.newPassWord, values.password)
                    setLoading(false)
                    setCurrentUser(data)
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Salvo com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    navigate('/auth/chooseFranchise')
                } catch (error: any) {
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                          errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        await Swal.fire({
                          icon: 'error',
                          title: 'Ops! Revise seu formulário e tente novamente!',
                          html: errorText
                        });
                      } else {
                        await Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: error.response.data.message,
                          timer: 2000
                        })
                      }
                }
            }
        })

    };

    const sawLoadingSpinner = () => {
        return Swal.fire({
            position: 'center',
            icon: 'info',
            title: "Alterando a senha...",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
    }

    const swalComponent = (loading) => {
        if (loading) {
            sawLoadingSpinner();
        }
    }

    return (

        <>
            {swalComponent(loading)}
            {loadingPage ? <LoadingSpinner title={"Carregando"} /> :
                <ResetPassWordForm formik={formik} />
            }
        </>
    )
}
export const ResetPassWordPage = () => {
    return (
        <>
            <Update />
        </>
    )
}