import { FC } from "react";
import ProfileForm from "./ProfileForm";
import ContactForm from "./ContactForm";
import BankDataForm from "./BankDataForm";
import AddressForm from "../Address/AddressForm";
import { AdressProvider } from "../../coreGlobal/providers/AdressProvider";
import MedicalConditionDataForm from "./MedicalConditionDataForm";
import PersonObservationsForm from "./PersonObservations";

type Props = {
    formik: any,
    type: number,
    complete: string,
    mode?: "UPDATE" | "CREATE"
}
const PeopleForm: FC<Props> = ({ formik, type, complete, mode }) => {
    
    return (
        <>
            <ProfileForm formik={formik} type={type} complete={complete} mode={mode} />
            <ContactForm formik={formik} complete={complete} />

            <div className="card mb-5 mb-xl-10 shadow-sm">
                <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_adress_card_collapsible">
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Endereço:</h3>
                    </div>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1"></i>
                    </div>
                </div>
                <div id="kt_adress_card_collapsible" className="collapse show">
                    <div className='card-body border-top p-9'>
                        <AdressProvider>
                            <AddressForm formik={formik} complete={complete} />
                        </ AdressProvider>
                    </div>
                </div>
            </div>

            <BankDataForm formik={formik} />

            <MedicalConditionDataForm formik={formik} MedicalConditionsData={formik.values.medicalConditions} />

            <PersonObservationsForm formik={formik} PersonObservationsData={formik.values.medicalConditions} />

        </>
    )
}
export default PeopleForm