import axios, {AxiosResponse} from 'axios'
import {UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const USERS_URL = `${API_URL}/users`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
    return axios
        .get(`${USERS_URL}?${query}`)
        .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

export {getUsers};