interface RoomInfo {
    room: any
    formik: any
    client: any
}

export const RoomSchedule = ({ room, formik, client }: RoomInfo) => {

    return <>
        <div className="row w-100" style={{ justifyContent: 'center' }}>
            <div className='row border border-white'>
                <div className='col-12 mb-1 fw-bold d-block'>
                    <span className='text-danger'> Cliente:</span> {client?.name}
                </div>
            </div>
            <div className='col' >
                <div className='d-flex justify-content-center align-items-center mt-3 mb-2'>

                    <div className='row border border-white'>
                        <div className='col-12 mb-2 fw-bold d-block '>
                            <p>Selecione a sala de preferência para o atendimento.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-6">
                            {room?.map((data, index) => (

                                <div key={index} className="col-12 mb-4">
                                    <button
                                        className={'btn btn-light btn-lg border border-dark '}
                                        style={{ backgroundColor: data?.color, color: 'black', width: '100%' }}
                                        onClick={() => { formik.setFieldValue('room', data?.id) }}
                                    >
                                        {data?.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}