import Swal from "sweetalert2"

export const copyToBudget = async (value: String) => {
    try {
        const baseUrl = "https://painel.drlaser.com.br/budget/" + value
        await navigator.clipboard.writeText(baseUrl)
        await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Link copiado com sucesso!',
            timer: 2000
        })
    } catch (error: any) {
        await Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.response.data.message,
            timer: 2000
        })
    }
}