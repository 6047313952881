import axios, {AxiosResponse} from 'axios'
import {IGiftSaleRequest, IGiftSaleResponse, ISaleRequest, ISaleResponse} from './models/sale'
import {SimpleResponse} from '../../../../_metronic/helpers'
import { Response } from '../../../../_metronic/helpers'
import { ISelect, ISelectPaymentOption } from '../../../../coreGlobal/models/default'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const PRODUCT_URL = `${API_URL}/products`
const SALE_URL = `${API_URL}/sales`

const getProductById = (productId: string | number) => {
  return axios
    .get(`${PRODUCT_URL}/${productId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const CATEGORY_URL = `${API_URL}/products/categories`

const getAllCategories = () => {
  return axios
    .get(`${CATEGORY_URL}?include=products`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const PEOPLE_URL = `${API_URL}/people`

const listClients = (query) => {
  return axios
    .get(`${PEOPLE_URL}/find${query}&include=sales.items.product,sales.items.scheduleItems,budgets,lastClientHistory,clientHistory,medicalConditions`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getClient = (clientId) => {
  return axios
    .get(`${PEOPLE_URL}/${clientId}?include=sales.items`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const generateGiftSaleReq = (sale: IGiftSaleRequest): Promise<IGiftSaleResponse | undefined> => {
  return axios
    .post(`${SALE_URL}`, sale)
    .then((response: AxiosResponse<SimpleResponse<IGiftSaleResponse>>) => response.data)
    .then((response: SimpleResponse<IGiftSaleResponse>) => response.data)
}

const generateSale = (sale: ISaleRequest): Promise<ISaleResponse | undefined> => {
  return axios
    .post(`${SALE_URL}?include=billings`, sale)
    .then((response: AxiosResponse<SimpleResponse<ISaleResponse>>) => response.data)
    .then((response: SimpleResponse<ISaleResponse>) => response.data)
}

const sendLinkChargeToCustomer = (saleId) => {
  return axios.post(`${SALE_URL}/${saleId}/send`)
}

const FRANCHISE_URL = `${API_URL}/franchises`

const getFranchises = () => {
  return axios
    .get(`${FRANCHISE_URL}/all`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const USERS_URL = `${API_URL}/users`

const getUsers = (): Promise<ISelect[] | undefined> => {
  return axios
  .get(`${USERS_URL}/all`)
  .then((response: AxiosResponse<Response<ISelect[]>>) => response.data)
  .then((response: Response<ISelect[]>) => response.data)
}

const EDIT_CLIENT_URL = `${API_URL}/people`

const editContact = (clientId: string | number, contact: any) => {
  return axios
    .put(`${EDIT_CLIENT_URL}/${clientId}`, contact)
    .then((response: any) => response.data)
}

const PAYMENT_OPTION_URL = `${API_URL}/payment_options/all?per_page=100`

const getPaymentOption = (): Promise<ISelectPaymentOption[] | undefined> => {
  return axios
  .get(`${PAYMENT_OPTION_URL}`)
  .then((response: AxiosResponse<Response<ISelectPaymentOption[]>>) => response.data)
  .then((response: Response<ISelectPaymentOption[]>) => response.data)
}

export {
  getProductById,
  getAllCategories,
  listClients,
  getClient,
  generateSale,
  sendLinkChargeToCustomer,
  getFranchises,
  getUsers,
  editContact,
  getPaymentOption,
  generateGiftSaleReq
}
