// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'
import {Link} from 'react-router-dom'
import useAuthorization from '../../../../../hooks/useAuthorization'

type Props = {
  row: Row<User>
}

const CustomRow: FC<Props> = ({row}) => {
  const id = String(row.original.id) as string
  const isAuthorizedToUpdate = useAuthorization('budgets.update')

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.value === null ? '-' : cell.render('Cell')}
          </td>
        )
      })}

      {isAuthorizedToUpdate && (
        <td className='text-center min-w-100px'>
          <Link className='btn btn-primary ms-2' to={`/sales/create?budget=${id}`}>
            Editar
          </Link>
        </td>
      )}
    </tr>
  )
}

export {CustomRow}
