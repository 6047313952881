import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { PeopleProvider, usePeople } from '../../coreGlobal/providers/PersonProvider'
import { AdressProvider, useAdress } from '../../coreGlobal/providers/AdressProvider'
import { FranchiseProvider } from '../franchise/core/franchiseProvider'
import { UsersForm, UsersFormProvider } from './core/UsersFormProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { TEditUserRequest, TUserResquest } from '../../coreGlobal/models/user'
import { useFormik } from 'formik'
import { userSchema } from './core/validation/UserVali'
import { userBuildRequest } from './core/UserBuildRequest'
import Swal from 'sweetalert2'
import { LoadingSpinner } from '../../utils/loading/loadingSpinner'
import { UserForm } from './components/UserForm'
import { cpfMask } from '../../utils/cpfMask'
import { phoneMask } from '../../utils/phoneMask'
import { updateUser } from './core/_resquest'

const UserBreadcrumbs: Array<PageLink> = [
  {
    title: 'Usuário',
    path: 'user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const Update = () => {
  let { id } = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const { persons, setPersons } = usePeople()
  const { getDataStates, dataStates, dataCep } = useAdress()
  const {
    getDataGroups,
    getDataFranchises,
    setLoading,
    getDataUser,
    user,
    setSelectsFranchises,
    setGroup,
  } = UsersForm()

  const navigate = useNavigate()
  const [userInitialValues] = useState<TUserResquest>({
    name: '',
    cpfUser: '',
    email: '',
    phoneUser: '',
    person: {
      id: -1,
      type: -1,
      name: '',
      email: '',
      contacts: [],
      bank: [],
      createdAt: '',
      updatedAt: '',
      reduceTime: true,
    },
    password: '',
    franchises: [],
    roles: [],
    active: true
  })

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      const data = await getDataUser(String(id))
      parseToFormik(data)
      getDataFranchises()
      getDataStates()
      getDataGroups()
      setLoadingPage(false)
    }
    init()
  }, [])
  useEffect(() => {
    if (!dataCep?.data.message) {
      formik.setFieldValue('street', dataCep?.data.address)
      formik.setFieldValue('neighborhood', dataCep?.data.neighbourhood)
      const findState = dataStates?.find((state) => state.uf === dataCep?.data.state)
      if (findState) {
        formik.setFieldValue('state', findState.id)
        const findCity = findState.cities?.find((city) => city.name === dataCep?.data.city)
        if (findCity) {
          formik.setFieldValue('city', findCity.id)
        }
      }
    } else {
      formik.setFieldValue('addresses[0].street', '');
      formik.setFieldValue('addresses[0].neighborhood', '');
      formik.setFieldValue('addresses[0].state', '');
      formik.setFieldValue('addresses[0].city', '');
      formik.setFieldValue('addresses[0].complement', '');
      formik.setFieldValue('addresses[0].number', '');

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Cep inválido"
      });
    }
  }, [dataCep])

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: userSchema(id),
    onSubmit: async (values) => {
      const valuesFilter = await userBuildRequest(
        values,
        Number(persons.map((person) => person.id))
      )
      submit(valuesFilter)
    },
  })

  const parseToFormik = async (user) => {
    await formik.setValues({
      name: user.name,
      cpfUser: cpfMask(user.cpf),
      email: user.email,
      phoneUser: phoneMask(user.phone),
      person: {
        id: user?.person?.id,
        type: user?.person?.type,
        name: user?.person?.name,
        email: user?.person?.email,
        contacts: [],
        bank: [],
        createdAt: user?.person?.createdAt,
        updatedAt: user?.person?.updatedAt,
        reduceTime: user?.person?.reduceTime,
      },
      password: '',
      franchises: user.franchises.map((value) => {
        return value.id
      }),
      roles: user.roles.map((value) => {
        return value.id
      }),
      active: user.active
    })

    const franchises = user.franchises.map((value) => {
      return { label: value.name, value: value.id }
    })
    setSelectsFranchises(franchises)

    if (user?.roles[0]) {
      const groups = user.roles?.map((value) => {
        return { label: value.name, value: value.id }
      })

      setGroup(groups)
    }

    if (user.person) {
      setPersons([user.person])
    }
  }

  const submit = async (req: TEditUserRequest) => {
    await Swal.fire({
      title: 'Deseja atualizar o acesso ao sistema?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updateUser(String(id), req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate('/users/list', { replace: true })
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }
  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Carregando tela editar usuário'} />
      ) : (
        <UserForm formik={formik} id={id} />
      )}
    </>
  )
}

export const UserEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={UserBreadcrumbs}> Editar Usuário</PageTitle>
      <PeopleProvider>
        <AdressProvider>
          <FranchiseProvider>
            <UsersFormProvider>
              <Update />
            </UsersFormProvider>
          </FranchiseProvider>
        </AdressProvider>
      </PeopleProvider>
    </>
  )
}
