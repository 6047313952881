import axios, { AxiosResponse } from 'axios'
import { IBudgetRequest } from '../../../coreGlobal/models/budgets/budget';
import { BudgetsQueryResponse } from './list/_model';

const API_URL = process.env.REACT_APP_API_URL + '/api'
const BUDGET_URL = `${API_URL}/budgets`

export const createBudget = (budget: IBudgetRequest) => {
  return axios
    .post(`${BUDGET_URL}`, budget)
    .then((response) => response.data)
    .then((response) => response.data)
}

export const updateBudget = (budget: IBudgetRequest, budgetd: string) => {
  return axios
    .put(`${BUDGET_URL}/${budgetd}`, budget)
    .then((response) => response.data)
    .then((response) => response.data)
}

export const getAllBudgets = (query: string): Promise<BudgetsQueryResponse> => {
  return axios
      .get(`${BUDGET_URL}?${query}`)
      .then((d: AxiosResponse<BudgetsQueryResponse>) => d.data)
}

export const getBudgetById = (budgetId: string | number) => {
  return axios
    .get(`${BUDGET_URL}/${budgetId}?include=franchise.person.contacts`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}



