/* eslint-disable react-hooks/exhaustive-deps */
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { createPerson } from '../../coreGlobal/_resquest'
import { TPersonRequest } from '../../coreGlobal/models/person'
import { PersonValidSchema } from '../../coreGlobal/validation/PersonVali'
import PeopleForm from '../../components/Person/PeopleForm'
import { PeopleProvider, usePeople } from '../../coreGlobal/providers/PersonProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { buildPersonRequest } from '../../coreGlobal/buildPersonRequest'
import { cepMask } from '../../utils/cepMask'
import { phoneMask } from '../../utils/phoneMask'
import { LoadingSpinner } from '../../utils/loading/loadingSpinner'

const peopleBreadcrumbs: Array<PageLink> = [
  {
    title: 'Editar Cliente',
    path: '/people',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const Update = () => {
  let { id } = useParams();
  const [loadingPage, setLoadingPage] = useState(false)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { persons, setReduceTimeRadio, setHideAvatarRadio, getDataPersons } = usePeople()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true);
      const person = await getDataPersons(String(id))
      parseToFormik(person)
      setLoadingPage(false);
    }
    init()
  }, [])


  const initialValues: TPersonRequest = {
    type: 1,
    name: '',
    preferredName: '',
    email: '',
    document: '',
    birthDate: '',
    gender: '',
    rg: '',
    job: '',
    nationality: -1,
    reduceTime: true,
    hideAvatar: false,
    contacts: [{
      type: 0,
      phone: ""
    }],
    addresses: [{
      isMain: false,
      zipCode: '',
      street: '',
      number: -1,
      complement: '',
      neighborhood: '',
      city: -1,
    }],
    bankData: [{
      isMain: false,
      bank: -1,
      accountType: -1,
      account: '',
      accountDv: '',
      agency: '',
      agencyDv: '',
      pix: '',
    }],
    medicalConditions: [],
    observations: []
  };

  const parseToFormik = async (persons) => {
    if (persons.length) {
      const addresses = persons[0].addresses?.map((value) => ({
        id: value.id,
        isMain: value.isMain,
        zipCode: cepMask(value.zipCode),
        street: value.street,
        number: value.number,
        neighborhood: value.neighborhood,
        city: value.city.id,
        state: value.city.state.id,
        ...(value.complement && { complement: value.complement }),
      }))

      const banksData = persons[0].bank?.map((value) => ({
        id: value.id,
        isMain: value.main,
        account: value.account,
        accountDv: value.accountDv,
        agency: value.agency,
        agencyDv: value.agencyDv,
        bank: String(value.bank.id),
        accountType: value.accountType.id,
        pix: value.pixKey,
        updatedAt: value.updatedAt,
        createdAt: value.createdAt,
      }))

      const MedicalConditionsData = persons[0].medicalConditions?.map((value, index) => ({

        id: value.id,
        name: value.name,
        status: value.status,
        filePath: value.clientMedicalCondition.filePath,
        documentAuthorized: value.clientMedicalCondition.filePath,
        authorized: value.clientMedicalCondition.authorized ? 1 : 0,
        observation: value.clientMedicalCondition.observation,
        createdBy: value.clientMedicalCondition.createdBy.name,
        updatedBy: value.clientMedicalCondition.updatedBy.name
      }))

      const contactsData = persons[0].contacts?.map((value) => ({
        id: value.id,
        type: value.typeId,
        phone: phoneMask(value.phone),
      }))

      const observations = persons[0].observations?.map((value) => ({
        id: value.id,
        observation: value.observation,
        user: value.user,
        createdAt: value.createdAt,
        updatedAt: value.updatedAt,
      }))

      await formik.setValues({
        id: persons[0].id,
        type: persons[0].type,
        name: persons[0].name,
        preferredName: persons[0].physicalPerson.preferredName || '',
        email: persons[0].email,
        document: persons[0].physicalPerson.document,
        birthDate: persons[0].physicalPerson.birthDate,
        gender: persons[0].physicalPerson.gender,
        rg: persons[0].physicalPerson.rg || '',
        job: persons[0].physicalPerson.job,
        nationality: persons[0]?.physicalPerson?.nationality?.id ? persons[0].physicalPerson.nationality.id : 22,
        addresses: addresses,
        contacts: contactsData.length > 0 ? contactsData : [{ type: 0, phone: "" }],
        bankData: banksData,
        medicalConditions: MedicalConditionsData,
        reduceTime: persons[0].reduceTime,
        hideAvatar: persons[0].physicalPerson.hideAvatar,
        observations: observations
      })
      setReduceTimeRadio(persons[0].reduceTime);
      setHideAvatarRadio(persons[0].physicalPerson.hideAvatar);
    }

  }
  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(true),
    onSubmit: async (values) => {
      const valuesFilter = buildPersonRequest(values)
      await submit(valuesFilter);
    }
  });
  const submit = async (req: FormData) => {
    await Swal.fire({
      title: 'Deseja atualizar o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createPerson(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          navigate('/people/list', { replace: true });
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      {loadingPage ? <LoadingSpinner title={"Carregando Dados Editar cliente"} /> :
        <>
          <PageTitle breadcrumbs={peopleBreadcrumbs}>Editar Cliente</PageTitle>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <PeopleForm formik={formik} type={1} complete={'required'} mode='UPDATE' />
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
              <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
                {!loading && 'Salvar'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </>
      }
    </>
  )
}

export const PeopleEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={peopleBreadcrumbs}>Atualizar Franquia</PageTitle>
      <PeopleProvider>
        <Update />
      </PeopleProvider>
    </>
  )
}