import dayjs from 'dayjs'
import {formatMonetaryToBr, removeMask} from '../../../../utils/formatMonetaryToBr'

interface ISplitInfo {
  value: number
  date: Date
  installments: number
}

function dividirValor(valor, partes) {
  var splitValues = (valor / partes).toFixed(2)
  var calDiff =
    Number(splitValues) * partes < valor
      ? valor - Number(splitValues) * partes + Number(splitValues)
      : 0

  if (calDiff === 0) {
    return Array(Number(partes)).fill(Number(splitValues))
  } else {
    let arrayDiff = Array(Number(partes) - 1).fill(splitValues)
    arrayDiff.push(calDiff)
    return arrayDiff
  }
}

export const SplitInfoCartao = ({value, date, installments}: ISplitInfo) => {
  const dateFormat = dayjs(date)
  const values = dividirValor(removeMask(value), installments)
  return (
    <>
      {values.map((val, index) => {
        return (
          <span className='fw-bold d-block'>
            {'Cartão'}: {index + 1}/{installments} - {formatMonetaryToBr(val)} -{' '}
            {dateFormat.add(index, 'month').format('DD/MM/YYYY')}
          </span>
        )
      })}
    </>
  )
}
