import axios, { AxiosResponse } from 'axios'
import {UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`

export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`

// Server should return AuthModel
export function login(email: string, cpf:string, password: string) {

  return axios.post(LOGIN_URL, {
    ...({cpf: cpf}),
    ...({email: email}),
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

type TUserByToken = {
  id: number;
  name: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
}

export function getUserByToken() {
  return axios.get<AxiosResponse<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL)
         .then((response)=> response.data)
         .then((response)=> response.data)
}

export function logOutUser() {
  return axios.post(`${API_URL}/logout`)
}