import { useQuery } from "react-query";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { QUERIES } from "../../../_metronic/helpers";
import { getGroupIdById } from "./core/_request";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { HeaderForShowPage } from "../../../components/HeaderForShowPage";
import { NotFoundIdShowPage } from "../../../components/NotFoundIdShowPage";
import { HasErrorInShowPage } from "../../../components/HasErrorInShowPage";
import { Spinner } from "../../../components/Spinner";
import { formatDateTime } from "../../../utils/dayJs/format";
import { getCategoryPermissions, getGroupPermission } from "../list/core/_requests";
import { parsePermissionCategoryName, parsePermissionName } from "../list/utils/parsePermission";
import React from "react";


interface ICategory {
  id: number;
  name: string;
  permissions: IPermission[]
}

interface IPermission {
  id: number;
  name: string;
  isChecked?: boolean
}

const PermissionsComponent = ({ data }) => {
  return (
      <div>
          {data.map(section => {
              const permissions = section.permissions
                  .filter(permission => permission.isChecked)
                  .map(permission => parsePermissionName(permission.name.toLowerCase()))
                  .filter((p)=> !p.includes("index"))
                  .join(', ');

              return (
                  <div key={section.id}>
                      <strong className="fw-bold">{parsePermissionCategoryName(section.name)}:</strong> {permissions}
                  </div>
              );
          })}
      </div>
  );
};

const groupBreadcrumbs: Array<PageLink> = [
    {
      title: 'Grupo',
      path: '/groups',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

export const GroupShowPage = () => {

    let { id } = useParams();

    const {
        isLoading,
        data,
        error,
      } = useQuery(
        `${QUERIES.GROUP_SHOW}-getById-${id}`,
        () => {
          return getGroupIdById(String(id))
        },
        {
          cacheTime: 0,
          enabled: true,
          onError: (err)=> {
            console.log(err)

          }
        },

      )

      const {
        isLoading: permissionsLoading,
        data: permissionsData,
        error: permissonsError,
      } = useQuery(
        `${QUERIES.GROUP_LIST}-getPermissons-${id}`,
        () => {
          return getGroupPermission(String(id))
        },
        {
          select: (values) => { return values; },
          cacheTime: 0,
          enabled: true,
          onError: (err)=> {
            console.log(err)
          }
        },

      )

      const {
        isLoading: categoryLoading,
        data: categoryData,
        error: categoryError,
      } = useQuery(
        `${QUERIES.GROUP_LIST}-getCategory`,
        () => {
          return getCategoryPermissions()
        },
        {
          cacheTime: 0,
          enabled: true,
          onError: (err)=> {
            console.log(err)
          }
        },

      )

      

    if(isLoading || categoryLoading || permissionsLoading){
        return <Spinner/>
    }


    if(error || categoryError || permissonsError){
       if(error instanceof AxiosError){
          const status = error.response?.status;

          switch (status) {
            case 404:
                return <NotFoundIdShowPage source="Grupo"/>
            default:
                return <HasErrorInShowPage/>
          }
       }

       return <HasErrorInShowPage/>
    }

    const { name, franchise,createdAt, updatedAt } = data;

    const treatGroupPermission = (data: any): number[] => {
      return data.permissions.map((permissao: any) => permissao.id);
    };

    const treatCategoriesPermissions = (categoriesPermissions: any[] | undefined, groupPermissions: number[]): any[] => {
      if (!Array.isArray(categoriesPermissions)) {
          console.error('Expected data to be an array, but got:', categoriesPermissions);
          return [];
      }
  
      return categoriesPermissions
          .map((category: any) => {
              const permissions = category.permissions
                  .filter(permission => groupPermissions.includes(permission.id))
                  .map(permission => ({
                      id: permission.id,
                      name: parsePermissionName(permission.name),
                      isChecked: true
                  }));
  
              return permissions.length > 0 ? { 
                  id: category.id, 
                  name: category.name, 
                  permissions 
              } : null;
          })
          .filter(category => category !== null);
  };
  

    
    const groupPermissions = permissionsData;
    const categoryPermissions = categoryData;
    const treatGroupPermissions = treatGroupPermission(groupPermissions);
    const treatCategoryPermissions = treatCategoriesPermissions(categoryPermissions, treatGroupPermissions);


    return (
        <>
        <PageTitle breadcrumbs={groupBreadcrumbs}>Dados do Grupo</PageTitle>
        <div className="card">
          <div className="card-body">
            <HeaderForShowPage title="Informações do Grupo"/>
            <p>Nome: {name || " "}</p>
            <p>Franquia: {franchise?.name || " "}</p>
            <p>Criado em: {createdAt ? formatDateTime(createdAt) : " "}</p>
            <p>Atualizado em: {updatedAt ? formatDateTime(updatedAt) : " "}</p>
            <p className="fw-bold">Permissões: </p>
            <p className="fw-normal">{<PermissionsComponent data={treatCategoryPermissions}/>}</p>
          </div>
        </div>
        </>
    );
}