import {SaleItemResponse} from '../sale'
import {IUserResponse} from '../user'
import {TSchedulesItems} from '../schedules/schedule'

export type MedicalRecordRequest = {
    itemId: number
    attendant: number
    status: number
    frequency?: string
    observation?: string | undefined
}

export type MedicalRecordResponse = {
    itemId: number
    sessionNumber: number
    status: string
    machineFrequency?: string
    observation?: string | undefined
    createdAt: string
    updatedAt: string
    scheduleItem?: TSchedulesItems,
    saleItem?: SaleItemResponse
    user?: IUserResponse,
    attendant?: IUserResponse,
}

export const MedicalRecordInitialValues: MedicalRecordRequest = {
    itemId: -1,
    attendant: -1,
    status: -1,
    frequency: '',
    observation: ''
}
export type MedicalRecordUpdateRequest = {
    id: number
    itemId: number
    attendant: number
    status: number
    frequency: string
    observation?: string | undefined
}

export const MedicalRecordUpdateInitialValues: MedicalRecordUpdateRequest = {
    id: -1,
    itemId: -1,
    attendant: -1,
    status: -1,
    frequency: '',
    observation: ''
}