import React, { FC, useEffect, useState } from 'react';

interface TimeSchedule {
    timeHour: string;
    time: any[];
    formik: any;
    clearDateInit: any;
    client: any
}

const ScheduleMarkinMinute: FC<TimeSchedule> = ({ timeHour, formik, time, clearDateInit, client }) => {
    const [selectedMinute, setSelectedMinute] = useState<string | null>(null);

    useEffect(() => {
        if (clearDateInit === true) {
            formik.setFieldValue('dateInit', '');
            setSelectedMinute('')
        }
        clearDateInit = false
    }, [clearDateInit]);

    if (!time || !timeHour) {
        return null;
    }

    const keyDay = timeHour.slice(0, -3);
    const keyMinute = timeHour.slice(-2);

    const timeArray = Object.keys(time).map((key) => ({
        date: key,
        minutes: time[key][keyMinute]?.map((minute) => minute), // Pegar apenas as horas (chaves do objeto)
    }));

    const handleMinuteClick = (minute: string) => {
        setSelectedMinute(minute);
        formik.setFieldValue('dateInit', `${timeHour}:${minute}`);
    };

    const renderTimeButtons = (minutes: string[]) => {
        return minutes.map((minute, index) => (
            <button
                key={index}
                type="button"
                style={{
                    backgroundColor: selectedMinute === minute ? '#D31145' : 'white',
                    borderColor: 'white',
                    border: 'aliceblue',
                    color: selectedMinute === minute ? 'white' : 'black',
                    borderRadius: '5px',
                    marginBottom: '5px',
                    marginRight: '5px',
                    padding: '15px',
                }}
                onClick={() => handleMinuteClick(minute)}
            >
                {keyMinute}:{minute.slice(0, -3)}
            </button>
        ));
    };
    
    const timeRows = timeArray.find((dayEntry) => dayEntry.date === String(keyDay))?.minutes;
    return (     
            <div className="row w-100" style={{ justifyContent: 'center' }}>
                <div className='row border border-white'>
                    <div className='col-12 mb-1 fw-bold d-block'>
                        <span className='text-danger'> Cliente:</span> {client?.name}
                    </div>
                </div>
                <div className="row border border-white mb-2">
                    <div className='col'>
                        <div className='d-flex justify-content-center align-items-center mt-3 mb-2'>
                            
                            <div className='row border border-white'>
                                <div className='col-12 mb-2 fw-bold d-block '>
                                    <p>Selecione o horário de preferência para o atendimento.</p>
                                </div>
                            </div>
                          
                        </div>
                        <div className='table-responsive mh-350px'>
                            <table className="table">
                                <tbody>
                                    {timeRows &&
                                        timeRows.reduce((rows, key, index) => {
                                            return (
                                                (index % 4 === 0
                                                    ? rows.push([key])
                                                    : rows[rows.length - 1].push(key)) && rows
                                            );
                                        }, [])
                                            .map((row, index) => (
                                                <tr key={index}>
                                                    <td className="text-center" style={{ padding: '10px' }}>{renderTimeButtons(row)}</td>
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export { ScheduleMarkinMinute };
