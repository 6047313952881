import { FC, useEffect, useRef, useState } from "react"
import Select from 'react-select';
import { usePeople } from "../../coreGlobal/providers/PersonProvider";
import { KTIcon } from "../../_metronic/helpers";
import { ISelectMedicalCondition } from "../../coreGlobal/models/medicalCondition";
import { downloadServerFile } from "../../coreGlobal/_resquest";

type Props = {
  formik: any,
  MedicalConditionsData: any
}

const getPreview = (file) => {

  const isFile = typeof file !== "string"
  if (!isFile) {
    return {
      isImage: !file.includes(".pdf")
    }
  }

  return {
    isImage: file.type.includes("image")
  }

}

const Preview = ({ file }: { file: File }) => {


  const { isImage } = getPreview(file)

  return (
    <>
      {isImage ?
        <>
          <div className="position-absolute d-flex align-items-end justify-content-center" style={{ width: 38, height: 42, backgroundColor: '#00000080' }}>
            <span className="text-white fs-9" >Alterar</span>
          </div>
          {/* <img style={{ width: 48, height: 48 }} src={url} /> */}
          <i className="fa-solid fa-image text-redlaser fs-1"></i>
        </>
        :
        <>
          <div className="position-absolute d-flex align-items-end justify-content-center" style={{ width: 38, height: 42, backgroundColor: '#00000080' }}>
            <span className="text-white fs-9" >Alterar</span>
          </div>
          <i className="fa-solid fa-file-pdf text-redlaser fs-2"></i>
          {/* <iframe
            src={url}
            title="PDF Preview"
            style={{ width: "48px", height: "48px", border: "none" }}
          /> */}
        </>
      }
    </>
  )
}
const FileInput = ({ formik, index }) => {
  const fileInputRef = useRef<any>(null);
  const previewInputRef = useRef<any>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue(`medicalConditions.[${index}].documentAuthorized`, file)
    }
  }

  const file = formik.values.medicalConditions[index].documentAuthorized
  const hasFile = file != null

  return (
    <>
      <button
        type="button"
        onClick={handleIconClick}
        ref={previewInputRef}
        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm position-relative'
      >
        {hasFile ?
          <Preview file={file} />
          :
          <i className="fa-solid fa-file-import"></i>
        }
      </button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".pdf, image/*"
      />
    </>
  )
}

const MedicalConditionDataForm: FC<Props> = ({ formik, MedicalConditionsData }) => {
  const { medicalCondition, setMedicalCondition, medicalConditions, getDataMedicalCondition } = usePeople()
  const [observation, setObservation] = useState('');

  const addField = (medicalCondition: ISelectMedicalCondition) => {
    setMedicalCondition(null)
    setObservation('')
    formik.setFieldValue('medicalConditions', [
      ...formik.values.medicalConditions,
      {
        id: medicalCondition.value,
        name: medicalCondition.label,
        status: medicalCondition.status,
        observation: observation,
        authorized: 0
      }
    ])
  }

  const removeField = (key: number) => {
    const newArray = [...formik.values.medicalConditions]
    newArray.splice(key, 1)
    formik.setFieldValue('medicalConditions', newArray)
  }

  useEffect(() => {
    getDataMedicalCondition()
  }, [])

  const downloadFile = async (path: string) => {
    await downloadServerFile(path);
  }

  const medicalConditionUsed = formik.values.medicalConditions;
  const medicalConditionAvailable = medicalConditions.filter(condition =>
    !medicalConditionUsed.some(used => used.id == condition.value)
  );

  return (
    <div className="card mb-5 mb-xl-10 shadow-sm">
      <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_medical_condtion_card_collapsible">
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Condição médica:</h3>
        </div>
        <div className="card-toolbar rotate-180">
          <i className="ki-duotone ki-down fs-1"></i>
        </div>
      </div>
      <div id="kt_medical_condtion_card_collapsible" className="collapse">
        <div className='card-body border-top p-9'>
          <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
            <div className="col-md-4 col-xxl-4">
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                onChange={(option) => {
                  setMedicalCondition(option)
                }}
                options={medicalConditionAvailable}
                value={medicalCondition}
                placeholder='Selecione a Condição Médica'
              />
            </div>
            <div className="col-md-6 col-xxl-6">
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Insira a observação *'
                onChange={(e) => {
                  setObservation(e.target.value)
                }}
                value={observation}
                maxLength={200}
              />
            </div>
            <div className="col-md-2 col-xxl-2">
              <button disabled={(medicalCondition === null || observation.length === 0)} onClick={(e) => {

                e.preventDefault();
                if (medicalCondition) {
                  addField(medicalCondition);
                }
              }} className="btn btn-primary">
                Adicionar Condição médica
              </button>
            </div>
          </div>
          <div className="row g-6 mb-6 mt-6 g-xl-9 mb-xl-9">
            <div className="col-md-12 col-xxl-12">
              <div className="card ">
                <div className="card-body flex-column">
                  <span className="fs-4 d-flex flex-left text-gray-800 fw-bold mb-2">Condição médica Cadastradas</span>
                  <div className="d-flex flex-center flex-wrap mb-5">
                    <table className="table table-row-dashed table-row-gray-300">
                      <thead>
                        <tr className="fw-bolder fs-6 text-gray-800">
                          <th>ID</th>
                          <th>Condição médica</th>
                          <th>Status</th>
                          <th>Autorização</th>
                          <th>Observação</th>
                          <th>Criado por</th>
                          <th>Alterado por</th>
                        </tr>
                      </thead>
                      <tbody>
                        {medicalConditionUsed?.map((field, index) => (
                          <tr key={index} className="align-middle">
                            <td>{field.id}</td>
                            <td>{field.name}</td>
                            <td>{field.status === "RESTRICTED" ? 'Restrito' : 'Ativo'}</td>
                            <td style={{ minWidth: '150px' }}>
                              <div className="row">
                                <div className="col-6">
                                  {field.status === "RESTRICTED" &&
                                    <select
                                      className='form-select form-select-lg form-select-solid'
                                      value={field.authorized}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        formik.setFieldValue(`medicalConditions.[${index}].authorized`, value)
                                        if (value === '0') {
                                          formik.setFieldValue(`medicalConditions.[${index}].documentAuthorized`, null)
                                        }
                                      }}
                                    >
                                      <option value='1'>Sim</option>
                                      <option value='0'>Não</option>
                                    </select>
                                  }
                                </div>
                                <div className="col-6">
                                  {formik.values.medicalConditions[index].authorized == '1' &&
                                    <span>
                                      <FileInput formik={formik} index={index} />
                                      {formik.values.medicalConditions[index].filePath && <button
                                        type="button"
                                        id={`${index}`}
                                        onClick={(e) => {
                                          downloadFile(formik.values.medicalConditions[index].filePath)
                                        }}
                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                      >
                                        <KTIcon iconName='eye' className='fs-3' />
                                      </button>}
                                    </span>
                                  }
                                </div>
                              </div>
                            </td>
                            <td>{field.observation}</td>
                            <td style={{ maxWidth: '70px' }}>{field.createdBy}</td>
                            <td style={{ maxWidth: '70px' }}>{field.updatedBy}</td>
                            {/* <td>
                              <button
                                type="button"
                                id={`${index}`}
                                onClick={(e) => {
                                  removeField(index)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </td> */}
                          </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MedicalConditionDataForm