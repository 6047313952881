import {initialValuesRequestToken, IValuesRequestToken, requestTokenSchema} from '../core/schemas'
import {useFormik} from 'formik'

import Select from 'react-select'
import {customStyles} from '../core/styles'

import Swal from 'sweetalert2'
import {useScheduleCart} from '../../../core/ClientScheduleProvider'
import {TValidateStep} from '../core/types'
import {useManagersGrantDiscount} from '../core/queries'
import {treatRequestToken} from '../utils/requestToken'
import {requestTokenManager} from '../core/_request'

export const RequestToken = ({
  setNameOfManager,
  setRequestStep,
  close,
}: {
  setNameOfManager: React.Dispatch<React.SetStateAction<string>>
  setRequestStep: React.Dispatch<React.SetStateAction<TValidateStep>>
  close: any
}) => {
  const {
    token: {session},
  } = useScheduleCart()
  const {data: managers} = useManagersGrantDiscount()

  const handleRequestToken = async (values: IValuesRequestToken) => {
    try {
      const request = treatRequestToken(session, values.manager, 'schedules.approve_execution')
      setNameOfManager(selectedValue.label)
      await requestTokenManager(request)
      setRequestStep('VALIDATE_TOKEN')
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Tente novamente mais tarde.',
        timer: 2000,
      })
    }
  }

  const formik = useFormik<IValuesRequestToken>({
    initialValues: initialValuesRequestToken,
    validationSchema: requestTokenSchema,
    onSubmit: handleRequestToken,
  })

  const selectedValue =
    managers.find((manager) => manager.value == formik.values.manager) ?? undefined
  const isSending = formik.isSubmitting
  const isValid = formik.isValid

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <h6 className='card-subtitle mt-4 mb-4 ms-5 text-align-center'>
          Solicite ao seu Gestor(a) o token para aprovaçāo.
        </h6>
        <div className='row mb-4 px-4'>
          <div className='col-lg-12'>
            <Select
              id='users'
              {...formik.getFieldProps('manager')}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              value={selectedValue}
              onChange={(option) => formik.setFieldValue('manager', option.value)}
              styles={customStyles}
              options={managers}
              placeholder='Gestor(a)'
            />
          </div>
        </div>
      </div>
      <span className='d-flex border-bottom border-1 py-2'></span>
      <div className='col-12 mt-6 border-top-2 pb-4 px-4'>
        <div className='d-flex flex-row align-items-center justify-content-end'>
          <button type='button' className='btn btn-secondary ' onClick={() => close()}>
            Cancelar
          </button>

          <button type='submit' className='btn btn-danger ms-5' disabled={!formik.isValid}>
            {isSending ? (
              <div className='d-flex flex-row align-items-center'>
                <div className='spinner-border spinner-border-sm text-light me-1' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
                Aguarde
              </div>
            ) : (
              'Requisitar token'
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
